import React from "react";

const Metrix = (props) => {
  const { data, style } = props;

  console.log(data);

  return (
    <div className="chart-container mt-2" style={style}>
      <div className="fc-white p-1 f-4 fw-bold">{data.title}</div>
      <div className="row flex">
        <div className="col-6">
          {data.data.map((location, index) => (
            <div className="row plant-elem" key={index}>
              <div className="col-9 fc-white f-4">{location}</div>
              <div className="col-3">
                <img src="/assets/active.svg" alt="Active Location" />
              </div>
            </div>
          ))}

          <div className="row">
            <div className="col-6 row">
              <img className="col-6 icon" src="/assets/active.svg" alt="Active" />
              <div className="fc-white f-4 col-6">Active</div>
            </div>
            <div className="col-6 row">
              <img className="col-6 icon" src="/assets/inactive.svg" alt="Inactive" />
              <div className="fc-white f-4 col-6">Inactive</div>
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="row flex flex-column">
            {data.chart.map((ch, index) => (
              <div key={index}>
                <div className="row flex mb-6">
                  {ch.value === "43" ? (
                    <img className="col-6 icon" src="/assets/red.svg" alt="Red Image" />
                  ) : (
                    <>
                      <img className="col-3 icon" src="/assets/star.svg" alt="Star Image" />
                      <img className="col-3 icon" src="/assets/trendArrow.svg" alt="Trend Arrow" />
                    </>
                  )}
                  <div className="col-6 fc-white f-12 fw-bold">{ch.value}</div>
                </div>

                <div className="fc-white f-8 fw-bold">{ch.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Metrix;
