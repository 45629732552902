/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import ReactECharts from "echarts-for-react";
import { CommonServiceContext } from "../../config/commonContext";

function GaugeChart(props) {
  const { data, style, buildingId } = props;
  const [option, setOption] = useState({});
  const [chartData, setChartData] = useState();
  const [chartType, setchartType] = useState("gauge");
  const commonContext = useContext(CommonServiceContext);

  function handlechartType(event) {
    setchartType(event.target.value);
  }
  useEffect(() => {
    if (data.key === "bId") {
      if (buildingId === "T07") {
        setChartData(data.data);
      } else if (buildingId === "SSIF") {
        setChartData(data.data1);
      } else if (buildingId === "R01") {
        setChartData(data.data2);
      } else if (buildingId === "U07") {
        setChartData(data.data3);
      } else if (buildingId === "M") {
        setChartData(data.data4);
      } else if (buildingId === "FGL") {
        setChartData(data.data5);
      } else if (buildingId === "R02") {
        setChartData(data.data6);
      } else if (buildingId === "G03") {
        setChartData(data.data7);
      } else if (buildingId === "I02") {
        setChartData(data.data8);
      } else {
        setChartData(data.data9);
      }
    } else {
      setChartData(data.data9);
    }
  }, [buildingId]);

  useEffect(() => {
    let newOption;

    if (chartType === "gauge") {
      newOption = {
        color: [
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: commonContext.colorStops.blueShade,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: commonContext.colorStops.greenShade,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: commonContext.colorStops.redShade,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: commonContext.colorStops.yellowShade,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: commonContext.colorStops.pinkPurple,
          },
        ],
        grid: {
          top: "10%",
          left: "3%",
          right: "4%",
          bottom: "3%",
        },

        xAxis: {
          show: false,
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: 0,
          textStyle: {
            color: "#ffffff",
            fontSize: 10,
          },
          show: false,
        },
        title: {
          text: data?.title ?? "",
          textStyle: {
            color: "#ffffff",
            fontSize: 10,
          },
          textAlign: "left",
          left: "3%",
          top: "1%",
        },

        series: [
          {
            type: "gauge",
            center: ["50%", "60%"],
            progress: {
              show: true,
              width: 18,
            },
            axisLine: {
              lineStyle: {
                width: 18,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              length: 0,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            axisLabel: {
              distance: 15,
              color: "white",
              fontSize: 10,
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 12,
              itemStyle: {
                borderWidth: 8,
              },
            },
            title: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              fontSize: 20,
              offsetCenter: [0, "70%"],
              textStyle: {
                color: "white",
              },
            },
            data: chartData || [0],
          },
        ],
      };
    } else if (chartType === "bar") {
      newOption = {
        grid: {
          top: "15%",
          left: "13%",
          right: "14%",
          bottom: "15%",
        },
        tooltip: {
          trigger: "item",
        },
        color: [
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: commonContext.colorStops.blueShade,
          },
        ],
        legend: {
          bottom: 0,
          textStyle: {
            color: "#ffffff",
            fontSize: 10,
          },
          show: true, // Show legend only for bar chart
        },
        title: {
          text: data?.title ?? "",
          textStyle: {
            color: "#ffffff",
            fontSize: 10,
          },
          textAlign: "left",
          left: "3%",
          top: "1%",
        },

        xAxis: {
          type: "category",
          axisLabel: {
            show: false,
          },
        },

        yAxis: {
          type: "value",
          axisLabel: {
            color: "white",
            fontSize: 8,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            type: chartType,
            name: chartData[0]?.name,
            data: chartData || [0],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    }

    setOption(newOption);
  }, [chartData, data, chartType]);

  return (
    <div className="chart-container mt-2">
      <select className="dropdown" value={chartType} onChange={handlechartType}>
        <option value="gauge" className="opt">
          Dial
        </option>
        <option value="bar" className="opt">
          Bar
        </option>
      </select>
      <ReactECharts option={option} style={style} />
    </div>
  );
}

export default GaugeChart;
