import React from "react";
import ReactECharts from "echarts-for-react";

function SimpleEncode(props) {
  const { data, style } = props;

  const option = {
    // dataset: {
    //   source: data.values,
    // },
    color: data.chartType === "funnel" ? ["#FF9800"] : ["#4CAF50"],
    title: {
      text: data.chartType === "funnel" ? "Bottom 5 Performance" : "Top 5 Performance",
      textStyle: {
        color: "#ffffff",
        fontSize: 12,
      },
      textAlign: "left",
      left: "3%",
      top: "1%",
    },
    // grid: {
    //   top: 40,
    //   left: 70,
    //   right: "25%",
    //   height: data.height ?? "70%",
    // },
    polar: {
      radius: [0, "80%"],
    },
    angleAxis: {
      min: 0,
      max: 100,
      startAngle: 90,
      splitLine: {
        show: false,
      },
    },
    // xAxis: {
    //   // min: 0,
    //   // max: 100,
    //   // name: "Amount",
    //   axisLabel: {
    //     color: "#FFFFFF",
    //     fontSize: 10,
    //   },
    //   splitLine: {
    //     show: false,
    //   },
    // },
    radiusAxis: {
      type: "category",
      data: data.xLabel,
    },
    tooltip: {},
    // yAxis: {
    //   type: "category",
    //   axisLabel: {
    //     color: "#FFFFFF",
    //     fontSize: 10,
    //   },
    // },
    // visualMap: {
    //   orient: "vertical",
    //   left: "right",
    //   min: 0,
    //   max: 100,
    //   text: ["High Score", "Low Score"],
    //   dimension: 0,
    //   inRange: {
    //     color: ["#FD665F", "#FFCE34", "#65B581"],
    //   },
    //   textStyle: {
    //     color: "#FFFFFF",
    //   },
    // },
    series: [
      {
        type: "bar",
        data: data.yLabel,
        // encode: {
        //   x: "value",
        //   y: "name",
        // },
        coordinateSystem: "polar",
        textStyle: {
          color: "#ffffff",
          fontSize: 8,
        },
        label: {
          show: true,
          position: "start",
          color: "#ffffff",
          formatter: "{b}",
        },
      },
    ],
  };

  return (
    <div className="chart-container mt-2">
      <ReactECharts option={option} style={style} />
    </div>
  );
}

export default SimpleEncode;
