// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/@fortawesome/fontawesome-free/css/all.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  scroll-behavior: smooth;
}

.App {
  background: hsla(219, 59%, 22%, 1);
}
table {
  width: 100%;
  border-collapse: collapse;
  padding: 1rem;
}

th,
td {
  border: 2px solid black;
  padding: 8px;
}

.float-right {
  float: right;
  margin-right: 8%;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kCAAkC;AACpC;AACA;EACE,WAAW;EACX,yBAAyB;EACzB,aAAa;AACf;;AAEA;;EAEE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":["@import \"~@fortawesome/fontawesome-free/css/all.css\";\r\n\r\nhtml {\r\n  scroll-behavior: smooth;\r\n}\r\n\r\n.App {\r\n  background: hsla(219, 59%, 22%, 1);\r\n}\r\ntable {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n  padding: 1rem;\r\n}\r\n\r\nth,\r\ntd {\r\n  border: 2px solid black;\r\n  padding: 8px;\r\n}\r\n\r\n.float-right {\r\n  float: right;\r\n  margin-right: 8%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
