import React, { useEffect, useContext, useState, useMemo } from "react";
import AdvisoryModel from "../common/advisoryModel.jsx";
import PieChart from "../echarts/pieChart";
import subsystems from "../../assets/subsystem.json";
import { HttpServiceContext } from "../../config/httpContext.js";
import DougnutChart from "../echarts/dougnutChart.jsx";
import Radar from "../echarts/radar.jsx";
import LineCharts from "../echarts/LineChartsJson.jsx";
import { useLocation } from "react-router-dom";
import BarChart from "../echarts/bar-chart.jsx";
import Metrix from "../echarts/metrix.jsx";

const Leftpanel = () => {
  const [activeTab, setActiveTab] = useState("advisory");
  const [data, setData] = useState();
  const [showAdv, setShowAdv] = useState(false);
  const [showIssue, setShowIssue] = useState("");
  const [show, setShow] = useState(false);
  const [subsystem, setSubsystem] = useState();
  const httpContext = useContext(HttpServiceContext);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      const pathParts = location.pathname.split("/");
      console.log(pathParts);
      setSubsystem(pathParts[2]);
    }
  }, [location]);

  useEffect(() => {
    async function getSubsystemCwpAdvisories() {
      let mydata = await httpContext.getSubsystemCwpAdvisories();
      if (mydata) {
        setData(mydata.data);
      }
    }
    getSubsystemCwpAdvisories();
  }, []);
  function handleClick() {
    setShow(true);
  }

  const handleSop = (event, id) => {
    setShowAdv(true);
    const advEntry = data.CCTV.find((adv) => adv.id === id);
    if (advEntry) {
      setShowIssue(advEntry);
    }
  };
  function sopTab() {
    setActiveTab("Incidents");
    setShowAdv(false);
  }

  function setTab() {
    setActiveTab("advisory");
  }

  const chartStyle = {
    height: "85vh",
    width: "100%",
  };
  return (
    <div className="h-100vh p-1">
      <div className="tabOptions">
        <div>
          <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
            Incidents
          </button>
        </div>
        {subsystems.cwp[subsystem] && (
          <div>
            <button onClick={() => setActiveTab("analytics")} className={`tabBtns ${activeTab === "analytics" ? "active" : null}`}>
              Analytics
            </button>
          </div>
        )}
        <div>
          <button onClick={() => setActiveTab("advisory")} className={`tabBtns ${activeTab === "advisory" ? "active" : null}`}>
            Advisory
          </button>
        </div>
        {/* <div>
                  <div className="mini ">
                    <img src="/maximize.png" className="maximize mb-3" alt="" onClick={openWindow}></img>
                  </div>
                </div> */}
      </div>
      <div className="tabContent">
        {activeTab === "analytics" && (
          <div className="row" style={{ height: "90vh" }}>
            {subsystems.cwp[subsystem].map((chData) =>
              chData.chartType === "pie" ? (
                <div className="col-4">
                  <DougnutChart data={chData} style={chartStyle} />
                </div>
              ) : chData.chartType === "city metrix" ? (
                <div className="col-4">
                  <Metrix data={chData} style={chartStyle} />
                </div>
              ) : chData.chartType === "radar" ? (
                <div className="col-4">
                  <Radar data={chData} style={chartStyle} />
                </div>
              ) : chData.chartType === "bar" ? (
                <div className="col-4">
                  <BarChart data={chData} style={chartStyle} />
                </div>
              ) : chData.chartType === "line" ? (
                <div className="col-4">
                  <LineCharts data={chData} style={chartStyle} />
                </div>
              ) : chData.chartType === "pie" ? (
                <div className="col-4">
                  <PieChart data={chData} style={chartStyle} />
                </div>
              ) : null
            )}
          </div>
        )}

        {activeTab === "advisory" && (
          <table className="d-block text-light table px-0" style={{ height: "95vh" }}>
            <tr className="d-flex justify-content-between  align-items-baseline pr_1rem ">
              <th className="col-11 fs-6 px-4">Observation</th>
              <th className="col-auto px-3 fs-6">Action</th>
              <th onClick={handleClick} className="col-auto mr-2">
                <i className="fa-solid fa-expand" style={{ height: "1em" }}></i>
              </th>
            </tr>
            {data &&
              data[subsystem]?.map((adv) => (
                <tr key={adv.id} className="d-flex justify-content-between px-4">
                  <td className="col-9 text-justify py-1 border-0 text fs-7">{adv.observation}</td>
                  <td>
                    <button key={adv.id} className=" col-auto myBtns text-white cursor-pointer" onClick={(event) => handleSop(event, adv.id)}>
                      ACT
                    </button>
                  </td>
                </tr>
              ))}
          </table>
        )}
        {activeTab === "Incidents" && (
          <div className=" d-block">
            <iframe src={httpContext.sop} title="Sop" style={{ height: "30vh", width: "100%" }}></iframe>
          </div>
        )}
      </div>
      {showAdv && <AdvisoryModel showIssue={showIssue} closeadv={() => setShowAdv(false)} sopTab={sopTab}></AdvisoryModel>}
      {show && (
        <div className="modal d-block modal-xl z-0" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-between px-2">
                <h5 className="modal-title">Advisory</h5>
                <div className="w-10">
                  <button type="button" className="close btn bttn" onClick={() => setShow(null)} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="modal-body p-2 justify-content-between">
                {data &&
                  data.advisory &&
                  data.advisory.traffic.map((adv) => (
                    <tr key={adv.id} className="mdCont">
                      <td className="text-justify py-2 border-0 fc-white">{adv.observation}</td>
                      <td>
                        <button key={adv.id} className="myBtns text-white cursor-pointer" onClick={(event) => handleSop(event, adv.id)}>
                          ACT
                        </button>
                      </td>
                    </tr>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Leftpanel;
