/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import Model from "../model/model";
import LineChart from "../echarts/LineChartDynamic";
import { HttpServiceContext } from "../../config/httpContext";
import { CommonServiceContext } from "../../config/commonContext";
import { useLocation } from "react-router-dom";
import subsystems from "../../assets/subsystem.json";
import DougnutChart from "../echarts/dougnutChart";
import { useNavigate } from "react-router-dom";
import LineCharts from "../echarts/LineChartsJson";
import BmsTabs from "../common/dashboardtabs";
import Iframe from "../common/iframes";
import AdvisoryModel from "../common/advisoryModel";
import Buildingdata from "../../assets/building.json";
import { ModelServiceContext } from "../../config/modelContext";
import GaugeChart from "../echarts/gaugeChart";
import PropagateLoader from "react-spinners/PropagateLoader";
import { Stack } from "react-bootstrap";
import { Autocomplete, TextField } from "@mui/material";

function BmsOverview() {
  const [buildingId, setBuildingId] = useState();
  const [activeTab, setActiveTab] = useState("analytics");
  const [showAdv, setShowAdv] = useState(false);
  const [showIssue, setShowIssue] = useState("");
  const httpContext = useContext(HttpServiceContext);
  const commonContext = useContext(CommonServiceContext);
  const modelContext = useContext(ModelServiceContext);
  const [data, setData] = useState();
  const [showWindow, setShowWindow] = useState(false);
  const [showframe, setShowframe] = useState(false);
  const [show3dView, setShow3dView] = useState(false);
  const [showAiPromt, setShowAiPromt] = useState(true);
  const [askAi, setAskAi] = useState(false);
  const [aiInput, setAiInput] = useState();
  const [aiIframe, setAiIframe] = useState("");
  const [visioMap, setVisioMap] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [inputSuggestions, setInputSuggestions] = useState([
    "Can you analyze the hour column to show how alarms are distributed throughout the day? Please identify peak and non-peak hours and any notable patterns.",
    "Can you analyze the correlation between the severity of alarms and the type of issue in the issues column? Please provide insights into which issues are linked to higher severity alarms and any patterns or trends that emerge. and represent using plots",
    "Identify trends in alarm occurrences based on the DayOfWeek column and plot pie chart for this.",
    "Can you analyze alarm occurrences across different buildings using the building column and identify any trends?",
    "Can you analyze how the severity of alarms changes over time? Use the timestamp data to identify any trends in alarm severity over different periods, such as months or years.",
    "Can you analyze if certain buildings are associated with specific types of issues? Identify any buildings that have a higher frequency of certain issues compared to others.",
    "Can you plot line chart for month wise alarm",
    "Identify trends in alarm frequency based on the year.",
  ]);

  const visio = useRef();
  const model = useRef();
  const location = useLocation();
  const nav = useNavigate();
  const handleSop = (event, id) => {
    setShowAdv(true);
    const advEntry = data.advisory.bms.find((adv) => adv.id === id);
    if (advEntry) {
      setShowIssue(advEntry);
    }
  };
  useEffect(() => {
    if (location.state) {
      const key = location.state.key;
      setBuildingId(key);

      setShow3dView(true);
    }
  }, [location]);
  useEffect(() => {
    async function getData() {
      let mydata = await httpContext.getData();
      if (mydata) {
        setData(mydata.data);
      }
    }
    getData();
  }, []);

  const sendMessageToIframe = () => {
    const msgdata = {
      buildingID: "BT07",
      floorID: "BT07-UL0",
      mode: "floor",
    };
    visio?.current?.contentWindow.postMessage(msgdata, "*");
  };

  const sendMessageToModelIframe = (msgdata) => {
    model?.current?.contentWindow.postMessage(msgdata, "*");
    visio?.current?.contentWindow.postMessage(msgdata, "*");
  };

  function openWindow() {
    setShowWindow(true);
  }
  function closeWindow() {
    setShowWindow(false);
  }
  function openIframe() {
    setShowframe(true);
  }
  function closeIframe() {
    setShowframe(false);
  }
  const limitedData = data && data.advisory && Array.isArray(data.advisory.bms) ? data.advisory.bms.slice(0, 6) : [];

  const leftPanel = {
    height: "25vh",
    height: "25vh",
    width: "100%",
  };

  const rightPanel = {
    height: "29vh",
    width: "100%",
  };

  function sopTab() {
    setActiveTab("Incidents");
    setShowAdv(false);
  }
  function alarm() {
    nav("/alarm");
  }
  useEffect(() => {
    const handleMessage = (event) => {
      const data = event.data;
      if (typeof data === "string") {
        setBuildingId(data);
      }
      if (typeof data === "object") {
        if (data.message === "Launch Digital Twin") {
          setShow3dView(true);
        }

        if (data.message === "Show AHU") {
          commonContext.setShowAssetAnalytics(true);
        }
        if (data.message === "navigate T07") {
          setTimeout(() => {
            nav("/alarm", { state: { key: "T07" } });
          }, 500);
        }
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    commonContext.setBId(buildingId);
    let intervalId;
    poolDeviceState();
    intervalId = setInterval(() => {
      poolDeviceState();
    }, 1000);
    return () => clearInterval(intervalId);
    // } else {
    //   commonContext.setAhuIncident(false);
    //   sendMessageToModelIframe({ state: false });
    //   commonContext.ahutemperaturedata(2200, 2800);
    // }
  }, [buildingId]);

  const poolDeviceState = () => {
    httpContext.getDeviceState().then((data) => {
      if (data["SIM-003"].state === true) {
        commonContext.setAhuIncident(true);
        sendMessageToModelIframe({ ahustate: true });
        commonContext.ahutemperaturedata(2800, 3200);
        modelContext.setAhuIncident(true);
      } else {
        commonContext.setAhuIncident(false);
        sendMessageToModelIframe({ ahustate: false });
        commonContext.ahutemperaturedata(2200, 2800);
        modelContext.setAhuIncident(false);
      }
      if (data["SIM-009"].state === true) {
        commonContext.setBearingIncident(true);
      } else {
        commonContext.setBearingIncident(false);
      }
      if (data["SIM-009"].state === true) {
        commonContext.setBearingIncident(true);
        sendMessageToModelIframe({ bearingState: true });
      } else {
        commonContext.setBearingIncident(false);
        sendMessageToModelIframe({ bearingState: false });
      }
    });
  };

  async function askAiContent() {
    setStartLoader(true);
    let mydata = await httpContext.getAiPromt(aiInput);
    setAiInput("");
    if (mydata) {
      setAiIframe(mydata.data.html_path);
      setStartLoader(false);
    } else {
    }
  }

  function updateInputValue(event) {
    setAiInput(event.target.value);
  }

  return (
    <div className="h-100vh p-1">
      <div className={`background-data ${commonContext.openModel ? "disabled" : ""}`}>
        {!commonContext.showAnalytics && !showWindow && !showframe && (
          <div className="row">
            <div className="col-7">
              <th className="mini col-6">
                <img src="/maximize.png" className="maximize" alt="" onClick={openIframe}></img>
              </th>
              <br />

              {!show3dView && (
                <iframe
                  ref={visio}
                  className="iframe"
                  src={`${visioMap ? httpContext.visioWebURL : commonContext.gmapURL}`}
                  title="visioWeb"
                ></iframe>
              )}
              {show3dView && (
                <div className="iframe position-relative">
                  <Model ref={model}></Model>
                </div>
              )}

              <div className="mytabs">
                <div className="tabOptions">
                  <div>
                    <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                      Incidents
                    </button>
                  </div>
                  <div>
                    <button onClick={() => setActiveTab("analytics")} className={`tabBtns ${activeTab === "analytics" ? "active" : null}`}>
                      Analytics
                    </button>
                  </div>
                  <div>
                    <button onClick={() => setActiveTab("advisory")} className={`tabBtns ${activeTab === "advisory" ? "active" : null}`}>
                      Advisory
                    </button>
                  </div>

                  <div>
                    <th className=" mini col-6">
                      <img src="/maximize.png" alt="" className="maximize" onClick={openWindow}></img>
                    </th>
                  </div>
                </div>
                <div className="tabContent">
                  {activeTab === "analytics" && (
                    <div className="row">
                      {subsystems.bms.overview.leftpanel.map((item) => {
                        if (item.type === "Img") {
                          if (!buildingId) {
                            const defaultImageSrc = require("../../assets/images/green.png");
                            const overall = 46;
                            return (
                              <div className="col-4">
                                <div className="chart-container  mt-2">
                                  <div className="leedTitle py-1 px-2">{item.title}</div>
                                  <div className="pt-2 pb-2  ">
                                    <img src={defaultImageSrc} alt="Default LEED score" className="certifyLeed" />
                                  </div>
                                  <div className="leedScore p-2">Score: {overall}</div>
                                </div>
                              </div>
                            );
                          }

                          for (let i = 0; i < Buildingdata.length; i++) {
                            const details = Buildingdata[i];
                            if (details.id === buildingId) {
                              const imageSrc =
                                details.leedScore >= 40 && details.leedScore <= 49
                                  ? require("../../assets/images/green.png")
                                  : details.leedScore >= 50 && details.leedScore <= 59
                                  ? require("../../assets/images/silver.png")
                                  : details.leedScore >= 60 && details.leedScore <= 79
                                  ? require("../../assets/images/gold.png")
                                  : details.leedScore >= 80
                                  ? require("../../assets/images/platinum.png")
                                  : require("../../assets/images/green.png");

                              return (
                                <div className="col-4" key={details.id}>
                                  <div className=" chart-container  mt-2">
                                    <div className="leedTitle py-1 px-2">{item.title}</div>
                                    <div className="pt-2 pb-1 ">
                                      <img src={imageSrc} alt={`LEED score ${details.leedScore}`} className="certifyLeed" />
                                    </div>
                                    <div className="leedScore p-2">Score: {details.leedScore}</div>
                                  </div>
                                </div>
                              );
                            }
                          }

                          return null;
                        } else if (item.type === "pie") {
                          return (
                            <div className="col-4" key={item.id}>
                              <DougnutChart data={item} style={leftPanel} buildingId={buildingId} />
                            </div>
                          );
                        } else if (item.type === "gauge") {
                          return (
                            <div className="col-4" key={item.id}>
                              <GaugeChart data={item} style={leftPanel} buildingId={buildingId} />
                            </div>
                          );
                        }
                      })}
                    </div>
                  )}

                  {activeTab === "advisory" && (
                    <div className="p-1">
                      {buildingId === "T07" ? (
                        <table className="d-block text-light table px-0" style={{ height: "29vh" }}>
                          <tr className="d-flex justify-content-between  align-items-baseline pr_1rem ">
                            <th className="col-11 fs-6 px-4">Observation</th>
                            <th className="col-auto px-0 fs-6">Action</th>
                          </tr>
                          {limitedData.map((adv, i) => {
                            if (i === 0 && !commonContext.ahuIncident) {
                              return null;
                            }
                            return (
                              <tr key={adv.id} className="d-flex justify-content-between px-4">
                                <td className="col-10 text-justify py-1 border-0 text fs-7">
                                  {adv.observation}
                                  <span
                                    className="span-color"
                                    onClick={() => {
                                      sendMessageToIframe();
                                    }}
                                  >
                                    {adv?.assetId}
                                  </span>
                                </td>
                                <td>
                                  <button
                                    key={adv.id}
                                    className="col-auto myBtns text-white cursor-pointer"
                                    onClick={(event) => handleSop(event, adv.id)}
                                  >
                                    ACT
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      ) : (
                        <table className="d-block text-light table px-0" style={{ height: "29vh" }}>
                          <tr className="d-flex justify-content-between  align-items-baseline pr_1rem ">
                            <th className="col-11 fs-6 px-4">Observation</th>
                            <th className="col-auto px-3 fs-6">Action</th>
                          </tr>
                          {data &&
                            data.advisory &&
                            data.advisory.bms.map((adv, i) => {
                              if (i === 0 && !commonContext.ahuIncident) {
                                return null;
                              }
                              return (
                                <tr key={adv.id} className="d-flex justify-content-between px-4">
                                  <td className="col-10 text-justify py-1 border-0 text fs-7">
                                    {adv.observation}
                                    <span
                                      className="span-color"
                                      onClick={() => {
                                        sendMessageToIframe();
                                      }}
                                    >
                                      {adv?.assetId}
                                    </span>
                                  </td>
                                  <td>
                                    <button
                                      key={adv.id}
                                      className=" col-auto myBtns text-white cursor-pointer"
                                      onClick={(event) => handleSop(event, adv.id)}
                                    >
                                      ACT
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </table>
                      )}
                    </div>
                  )}
                  {activeTab === "Incidents" && <iframe className="sop-iframe" src={httpContext.sop} title="Sop"></iframe>}
                </div>
              </div>
            </div>
            <div className="col-5 my-1">
              <div className="d-flex justify-content-between fc-white m-1">
                <div>Overview</div>
                {/* {(show3dViewButton || buildingId === "T07") && (
                  <div className="mx-1">
                    <button
                      className="btn bttn ml-auto"
                      onClick={() => {
                        setShow3dView(!show3dView);
                      }}
                    >
                      {!show3dView ? "3D Building View" : "GIS Map"}
                    </button>
                  </div>
                )} */}
              </div>
              {subsystems.bms.overview.rightpanel.map((chData) =>
                chData.chartType === "line" ? (
                  <div className="pt-2" onClick={alarm}>
                    <div className="arrow pt-2">
                      <img src={require("../../assets/icons/arrowRight.svg").default} style={{ height: "1rem" }} alt=""></img>
                    </div>
                    <LineChart buildingId={buildingId} data={chData} style={rightPanel} />
                  </div>
                ) : chData.chartType === "stepLine" ? (
                  <div className="p-1">
                    <LineCharts buildingId={buildingId} data={chData} style={rightPanel}></LineCharts>
                  </div>
                ) : (
                  chData.chartType === "stackLine" && (
                    <div className="p-1">
                      <LineCharts buildingId={buildingId} data={chData} style={rightPanel}></LineCharts>
                    </div>
                  )
                )
              )}
            </div>
          </div>
        )}
        {showAdv && <AdvisoryModel showIssue={showIssue} closeadv={() => setShowAdv(false)} sopTab={sopTab}></AdvisoryModel>}

        {showframe && <Iframe closeIframe={closeIframe} active={activeTab} ref={model} label={"overviewMap"} buildingId={buildingId} />}
        {showWindow && <BmsTabs closeWindow={closeWindow} active={activeTab} label={"overviewTabs"} buildingId={buildingId} />}
        {showAiPromt && (
          <div>
            <div className="chatbot" onClick={() => setAskAi(!askAi)}>
              <img src="/chatbot-button.png" alt="" />
            </div>
            {askAi && (
              <div className="chatbot-body">
                <div className="chat">
                  <div className="text-center fw-bold m-2">Chat with Ai</div>
                  <div className="chat-window">
                    <div className="generated-data">
                      {!startLoader && <iframe className="aiIframe" src={aiIframe} frameborder="0" title="Ai Promt"></iframe>}
                      {startLoader && <PropagateLoader className="loader" size={15} color="white" />}
                    </div>
                    <div className="asked-question"></div>
                    <div className="text-center d-flex justify-content-around p-2">
                      <Stack spacing={2} sx={{ width: 20 }}>
                        <Autocomplete
                          freeSolo
                          disableClearable
                          value={aiInput}
                          options={inputSuggestions}
                          onInputChange={(event, newInputValue) => updateInputValue({ target: { value: newInputValue } })}
                          onKeyDown={(evt) => {
                            if (evt.key === "Enter") {
                              askAiContent();
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Ask Ai"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                                endAdornment: null,
                              }}
                            />
                          )}
                        />
                      </Stack>
                      <img className="m-2" onClick={() => askAiContent()} src="/send-white.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default BmsOverview;
