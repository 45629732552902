/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState, useMemo } from "react";
import SankeyChart from "../echarts/sankey";
import DougnutChart from "../echarts/dougnutChart";
import BarChart from "../echarts/bar-chart";
import subsystems from "../../assets/subsystem.json";
import { CommonServiceContext } from "../../config/commonContext.jsx";
import { HttpServiceContext } from "../../config/httpContext.js";
import LineCharts from "../echarts/LineChartsJson.jsx";
import { ModelServiceContext } from "../../config/modelContext.jsx";
import AdvisoryModel from "../common/advisoryModel.jsx";
import cloneDeep from "lodash/cloneDeep";
import SimpleEncode from "../echarts/simpleEncode.jsx";

import Pyramid from "../echarts/pyramid.jsx";

const Livabilityindex = () => {
  const [show, setShow] = useState(false);
  const [showWindow, setShowWindow] = useState(false);
  const [activeTab, setActiveTab] = useState("analytics");
  const [showAdv, setShowAdv] = useState(false);
  const [showIssue, setShowIssue] = useState("");
  const [livabilitydata, setLivabilityData] = useState();
  const [sopSuccessful, setSopSuccessFul] = useState(false);
  const httpContext = useContext(HttpServiceContext);
  const modelContext = useContext(ModelServiceContext);
  const commonContext = useContext(CommonServiceContext);
  const [source, setSource] = useState("Liveability Index");
  const [data, setData] = useState();
  const [anomalySims, setAnomalySims] = useState([]);

  const subsystemData = useMemo(() => cloneDeep(subsystems), []);

  useEffect(() => {
    getliviabilityindexData(source !== "Liveability Index" ? source : "");
  }, [source]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      poolDeviceState();
    }, 8000);
    getData();

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    checkPreviousState(commonContext.olddevice_state);
    commonContext.setOldDevice_State(commonContext.device_state);
  }, [commonContext.device_state]);

  useEffect(() => {
    if (commonContext.changedNodes.length) {
      commonContext.changedNodes.forEach((node) => {
        let d_state = commonContext.device_state[node].state;
        data?.advisory.livability.forEach(async (adv) => {
          if (adv.SimId === node) {
            let payload = {
              source: adv.source,
              state: d_state,
              value: adv.impactvalue,
            };
            let response = await httpContext.updateLivabilityIndex(payload);
            if (response?.message) {
              getliviabilityindexData(source !== "Liveability Index" ? source : "");
            }
          }
        });
      });
    }
  }, [commonContext.changedNodes]);

  async function getData() {
    let mydata = await httpContext.getData();
    if (mydata) {
      setData(mydata.data);
    }
  }

  const checkPreviousState = (data) => {
    const newChangedNodes = [];

    const currentDeviceState = commonContext.device_state;
    Object.entries(data).forEach(([key, value]) => {
      if (Object.keys(currentDeviceState).length && currentDeviceState[key]?.state !== value.state) {
        newChangedNodes.push(key);
      }
    });
    commonContext.setChangedNodes((prevChangedNodes) => {
      const combinedChangedNodes = new Set([...newChangedNodes]);
      return Array.from(combinedChangedNodes);
    });

    if (newChangedNodes.length > 0) {
      console.log("Changed nodes:", newChangedNodes);
    }
  };

  const poolDeviceState = () => {
    httpContext.getDeviceState().then((data) => {
      const newAnomalySims = [];

      Object.entries(data).forEach(([key, value]) => {
        if (value.state) {
          newAnomalySims.push(key);
        }
      });
      setAnomalySims((prevAnomalySims) => {
        const combinedSims = new Set([...newAnomalySims]);
        return Array.from(combinedSims);
      });

      commonContext.setDevice_State({ ...data });

      const isAhuIncident = data["SIM-003"]?.state === true;
      commonContext.setAhuIncident(isAhuIncident);
      modelContext.setAhuIncident(isAhuIncident);
    });
  };

  async function getliviabilityindexData(source) {
    let response = await httpContext.getliviabilityindex(source);
    if (response) {
      setLivabilityData(response);
    }
  }
  function handleClick() {
    setShow(true);
  }

  const handleSop = (event, id) => {
    setShowAdv(true);
    const advEntry = data?.advisory.livability.find((adv) => adv.id === id);
    if (advEntry) {
      setShowIssue(advEntry);
    }
  };
  const chartStyle = {
    height: "30vh",
    width: "100%",
  };
  const leftchartStyle = {
    height: "29vh",
    width: "100%",
  };
  function openWindow() {
    setShowWindow(true);
  }
  function closeWindow() {
    setShowWindow(false);
  }

  function closeSOP() {
    setSopSuccessFul(false);
  }

  function sopTab() {
    setActiveTab("Incidents");
    setShowAdv(false);
  }

  function setTab() {
    setActiveTab("advisory");
  }

  const handleSourceClick = (event) => {
    if (event.name !== source) {
      setSource(event.name);
      console.log(event);
    } else {
      setSource("Liveability Index");
    }
  };

  return (
    <div className="h-100vh p-1">
      {!showWindow && (
        <div>
          <div className="row ">
            <div className="col-7">
              <div className="sankey pb-2">
                {subsystemData?.livability_index?.charts?.leftpanel?.map((chdata, index) => {
                  if (chdata.chartType === "sankey") {
                    const chartData = { ...chdata, data: livabilitydata?.sankey };
                    return (
                      <div className="h-58 m-2" key={JSON.stringify(chdata)}>
                        <SankeyChart
                          data={chdata}
                          chartData={livabilitydata?.sankey}
                          onNodeClick={(event) => handleSourceClick(event)}
                          setTab={setTab}
                          anamoly={anomalySims}
                          actualvalue={livabilitydata?.actualTotal}
                          advisory={data?.advisory?.livability}
                          nodes={chdata.nodes}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
              <div className="tabOptions">
                <div>
                  <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                    Incidents
                  </button>
                </div>
                <div>
                  <button onClick={() => setActiveTab("analytics")} className={`tabBtns ${activeTab === "analytics" ? "active" : null}`}>
                    Analytics
                  </button>
                </div>
                <div>
                  <button onClick={() => setActiveTab("advisory")} className={`tabBtns ${activeTab === "advisory" ? "active" : null}`}>
                    Advisory
                  </button>
                </div>

                {/* <div>
                  <div className="mini ">
                    <img src="/maximize.png" className="maximize mb-3" alt="" onClick={openWindow}></img>
                  </div>
                </div> */}
              </div>
              <div className="tabContent">
                {activeTab === "analytics" && (
                  <div className="row p-1">
                    {subsystemData?.livability_index?.charts?.leftpanel.map((chData, index) => {
                      if (chData.chartType === "analytics") {
                        return chData.analytics.map((analyticData, analyticIndex) => {
                          return (
                            <>
                              {analyticData.chartType === "bar" && source === analyticData.id && (
                                <div className="col-6" key={analyticIndex}>
                                  <SimpleEncode data={analyticData} style={leftchartStyle} />
                                </div>
                              )}
                              {analyticData.chartType === "funnel" && source === analyticData.id && (
                                <div className="col-6" key={analyticIndex}>
                                  <SimpleEncode data={analyticData} style={leftchartStyle} />
                                </div>
                              )}
                            </>
                          );
                        });
                      }
                      return null;
                    })}
                  </div>
                )}

                {activeTab === "advisory" && (
                  <table className="d-block text-light table px-0 my-1" style={{ height: "29vh" }}>
                    <tr className="d-flex justify-content-between  align-items-baseline pr_1rem ">
                      <th className="col-11 fs-6 px-4">Observation</th>
                      <th className="col-auto px-3 fs-6">Action</th>
                    </tr>
                    {data?.advisory.livability.map(
                      (adv, index) =>
                        (source === "Liveability Index" ? true : livabilitydata.targetNode.includes(adv.source)) &&
                        (adv.SimId ? anomalySims.includes(adv.SimId) : true) && (
                          <tr key={adv.id} className="d-flex justify-content-between px-4">
                            <td className="col-9 text-justify py-1 border-0 text fs-7">{adv.observation}</td>
                            <td>
                              <button
                                key={adv.id}
                                className=" col-auto myBtns text-white cursor-pointer"
                                onClick={(event) => handleSop(event, adv.id)}
                              >
                                ACT
                              </button>
                            </td>
                          </tr>
                        )
                    )}
                  </table>
                )}
                {activeTab === "Incidents" && (
                  <div className=" d-block">
                    <iframe src={httpContext.sop} title="Sop" style={{ height: "30vh", width: "100%" }}></iframe>
                  </div>
                )}
              </div>
            </div>
            <div className="col-5 p-1">
              {subsystemData?.livability_index?.charts?.rightpanel.map((chData, index) => {
                if (chData.chartType === "pie") {
                  const chartData = { ...chData, data: livabilitydata?.halfDoughnut, title: `${livabilitydata?.source[0].name}` };
                  return (
                    <div className="p-1" key={chData.id}>
                      <DougnutChart data={chartData} style={chartStyle} />
                    </div>
                  );
                } else if (chData.chartType === "bar") {
                  const chartData = {
                    ...chData,
                    data: livabilitydata?.bar,
                    xLabel: livabilitydata?.targetNode,
                    title: `Pillars for ${livabilitydata?.source[0].name}`,
                  };
                  return (
                    <div className="p-1" key={chData.id}>
                      <BarChart data={chartData} style={chartStyle} />
                    </div>
                  );
                } else if (chData.chartType === "multiple") {
                  return (
                    <div className="row " key={chData.id}>
                      {chData.charts.map((cd, index) => {
                        const targetChartData = {
                          ...cd,
                          data: cd.chartType === "line" ? livabilitydata?.source : livabilitydata?.targets,
                          title: cd.chartType === "line" ? `Forecast - ${livabilitydata?.source[0].name}` : `Forecast - Pillars`,
                        };
                        return (
                          <div className="col-6 p-1" key={cd.id}>
                            <LineCharts data={targetChartData} style={chartStyle} />
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      )}
      {showAdv && <AdvisoryModel showIssue={showIssue} closeadv={() => setShowAdv(false)} sopTab={sopTab}></AdvisoryModel>}
    </div>
  );
};

export default Livabilityindex;
