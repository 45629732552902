import React from "react";
import subsystems from "../../assets/subsystem.json";
import LineChartStatic from "../echarts/lineChartStatic";

function Delta() {
  const chartStyle = {
    height: "100%",
    width: "100%",
  };
  return (
    <div className="chartContainer  h-100">
      {subsystems.bms.delta.map((dt) => {
        return (
          <div className="chart3">
            <LineChartStatic data={dt} style={{ chartStyle }}></LineChartStatic>
          </div>
        );
      })}
    </div>
  );
}

export default Delta;
