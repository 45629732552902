/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";
import Modal from "react-bootstrap/Modal";

function Sop({ closeSOP, settab }) {
  return (
    <div className="sop-body">
      <Modal show={true} onHide={() => closeSOP()} size="xs" className="w-10" centered>
        <Modal.Header className="justify-content-end">
          <Modal.Title>
            <div className="">
              <button type="button" className="close btn bttn" onClick={() => closeSOP()} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center p-20px">SOP Created Successfully!</div>
        </Modal.Body>
        <Modal.Footer className="text-center justify-content-center">
          <button className="btn bttn m-0" onClick={() => settab("realtime")}>
            View
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Sop;
