/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* global google */

import React, { useContext, useEffect, useRef, useState } from "react";
import { GoogleMapsOverlay } from "@deck.gl/google-maps";
import { PathLayer, IconLayer, BitmapLayer } from "@deck.gl/layers";
import { Tile3DLayer, TileLayer, MVTLayer } from "@deck.gl/geo-layers";
import ClipLoader from "react-spinners/PuffLoader";

import { ScenegraphLayer } from "@deck.gl/mesh-layers";
import TripBuilder from "./tripBuilder";
import DATA_URL from "../../../assets/trips.json";
import ProgressTracking from "./progressTracking";
import AccidentTabs from "../../common/dashboardtabs";
import { HttpServiceContext } from "../../../config/httpContext";
import { CommonServiceContext } from "../../../config/commonContext";
import DeckGL from "@deck.gl/react";
import { scaleLinear } from "d3-scale";
import config from "../../../config.json";

const GOOGLE_MAPS_API_KEY = "AIzaSyDMlVAALBCx-2tliIzQKST8qFwIyCvIRc4";
const GOOGLE_MAP_ID = "b5908005c4b10751";
const GOOGLE_MAPS_API_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=beta`;
const AMBULANCE_MODEL_URL = "./assets/ambulance_car_-_low_poly/scene.gltf";
const FIRE_TRUCK_MODEL_URL = "./assets/firetruck/scene.gltf";
const POLICE_MODEL_URL = "./assets/honda_civic_malaysia_police_car/scene.gltf";
export const COLORS = [
  [254, 235, 226],
  [251, 180, 185],
  [247, 104, 161],
  [197, 27, 138],
  [122, 1, 119],
];
const colorScale = scaleLinear().clamp(true).domain([0, 50, 100, 200, 300]).range(COLORS);
const INITIAL_VIEW_STATE = {
  latitude: 37.783403877011085,
  longitude: -122.42432091193467,
  zoom: 18,
  maxZoom: 36,
  bearing: 0,
  pitch: 60,
};

function getTooltip({ object }) {
  return (
    object && {
      html: `\
      <div><b>Distance to nearest tree</b></div>
      <div>${object.properties.distance_to_nearest_tree}</div>
      `,
      capturePointerMove: true,
    }
  );
}

const option1 = [
  { value: "option1", label: "Ignore" },
  { value: "option2", label: "Minor - File Report" },
  {
    value: "Deploy",
    label: "Major Incident Severity",
  },
];

const option2 = [
  { key: "Po", name: "Deploy Police" },
  { key: "Po-Amb", name: "Deploy Police and Ambulance" },
  { key: "Po-Amb-Fi", name: "Deploy Police, Fire and Ambulance" },
];

const option3 = [
  { key: "police", name: "Start Police" },
  { key: "fire", name: "Start Fire" },
  { key: "ambulance", name: "Start Ambulance" },
];

const trackdropdown = [
  { key: "police", name: "Track Police" },
  { key: "fire", name: "Track Fire" },
  { key: "ambulance", name: "Track Ambulance" },
];

const Accident = () => {
  const [selectedOption, setSelectedOption] = useState();
  const [selectedOptionType, setSelectedOptionType] = useState();
  const [gmap, setGmap] = useState();
  const [activeTab, setActiveTab] = useState("Progress");
  const [reachedDestination, setReachedDestination] = useState({
    police: false,
    ambulance: false,
    fire: false,
  });
  const mapRef = useRef(null);
  const [option, setOption] = useState({ tracking: true, showPaths: true });
  const [mapCenter, setMapCenter] = useState({
    lat: 37.783413877011085,
    lng: -122.42432091193467,
  });
  const [policeFrame, setPoliceFrame] = useState(null);
  const [ambulanceFrame, setAmbulanceFrame] = useState(null);
  const [fireFrame, setFireFrame] = useState(null);
  const [policeLastTimestamp, setPoliceLastTimestamp] = useState(0);
  const [ambulanceLastTimestamp, setAmbulanceLastTimestamp] = useState(0);
  const [fireTruckLastTimestamp, setFireTruckLastTimestamp] = useState(0);
  const [closecctv1, setCloseCCTV1] = useState(false);
  const [showWindow, setShowWindow] = useState(false);
  const [closecctv2, setCloseCCTV2] = useState(false);
  const [accidentDetails, setAccidentDetails] = useState({
    latitude: 37.783413877011085,
    longitude: -122.42432091193467,
    place: "",
  });
  const [workOrder, setWorkOrder] = useState(false);
  const [policeReached, setPoliceReached] = useState(false);
  const [ambulanceReached, setAmbulanceReached] = useState(false);
  const [fireReached, setFireReached] = useState(false);
  const [policeclosed, setPoliceClosed] = useState(false);
  const [ambulanceClosed, setAmbulanceClosed] = useState(false);
  const [fireClosed, setFireClosed] = useState(false);
  const [layers, setLayers] = useState();
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
  const [showRouteMessage, setShowRouteMessage] = useState(false);
  const [showRouteMessageDone, setShowRouteMessageDone] = useState(false);
  const [showDispatch, setShowDispatch] = useState(false);
  const policeAnimationRef = useRef(null);
  const ambulanceAnimationRef = useRef(null);
  const fireTruckAnimationRef = useRef(null);
  const [policeSopId, setPoliceSopId] = useState("");
  const [ambulanceSopId, setAmbulanceSopId] = useState("");
  const [fireSopId, setFireSopId] = useState("");
  var shouldCenter = true;
  let policeAnimation, ambulanceAnimation, fireTruckAnimation;

  const commonContext = useContext(CommonServiceContext);
  const httpContext = useContext(HttpServiceContext);

  let timestamp = 0;
  var destinationStatus = {
    police: false,
    ambulance: false,
    fire: false,
  };
  const policeTrips = DATA_URL.policeVans.map((waypoints) => new TripBuilder({ waypoints, loop: false }));
  const ambulanceTrips = DATA_URL.ambulances.map((waypoints) => new TripBuilder({ waypoints, loop: false }));
  const fireTruckTrips = DATA_URL.fireTrucks.map((waypoints) => new TripBuilder({ waypoints, loop: false }));

  const startEndPoints = [...DATA_URL.policeVans, ...DATA_URL.ambulances, ...DATA_URL.fireTrucks]
    .map((trip) => [trip[0], trip[trip.length - 1]])
    .flat();
  const endPoint = startEndPoints[1];

  useEffect(() => {
    setPlacesName();
    loadScript(GOOGLE_MAPS_API_URL);

    const interval = setInterval(() => {
      if (window.google) {
        clearInterval(interval);
        let iconData = [
          {
            name: "CCTV 1",
            coordinates: [37.783403877011085, -122.42420091193467],
            size: 40,
          },
          {
            name: "CCTV 2",
            coordinates: [37.783403877011085, -122.42450091193467],
            size: 40,
          },
        ];
        let accidentSpot = [
          {
            name: "Accident Spot",
            coordinates: [37.783403877011085, -122.42432091193467],
            size: 40,
          },
        ];
        let layer = [
          new TileLayer({
            id: "TileLayer",
            // data: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
            // data: "https://a.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png",
            data: "https://a.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png",
            maxZoom: 36,
            minZoom: 0,

            renderSubLayers: (props) => {
              const { boundingBox } = props.tile;

              return new BitmapLayer(props, {
                data: null,
                image: props.data,
                bounds: [boundingBox[0][0], boundingBox[0][1], boundingBox[1][0], boundingBox[1][1]],
              });
            },
            pickable: true,
          }),
          new IconLayer({
            id: "IconLayer",
            data: iconData,
            getIcon: () => ({
              url: "/assets/cctv.png",
              width: 128,
              height: 128,
            }),
            getPosition: (d) => [d.coordinates[1], d.coordinates[0], 5],
            getSize: (d) => d.size,
            pickable: true,
            onClick: (info, event) => {
              if (info.object.name === "CCTV 2") {
                setCloseCCTV2(true);
                setCloseCCTV1(false);
              } else if (info.object.name === "CCTV 1") {
                setCloseCCTV2(false);
                setCloseCCTV1(true);
              }
            },
          }),
          new IconLayer({
            id: "IconLayer",
            data: accidentSpot,
            getIcon: () => ({
              url: "/assets/accident-spot.png",
              width: 128,
              height: 128,
            }),
            getPosition: (d) => [d.coordinates[1], d.coordinates[0], 5],
            getSize: (d) => d.size,
            pickable: true,
          }),
        ];
        setLayers(layer);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [mapCenter]);

  useEffect(() => {
    if (commonContext.startPolice) startPoliceVanAnimation("police");
    if (commonContext.startAmbulance) startPoliceVanAnimation("ambulance");
    if (commonContext.startFire) startPoliceVanAnimation("fire");
  }, [commonContext.startPolice, commonContext.startAmbulance, commonContext.startFire]);

  useEffect(() => {
    if (policeReached) {
      httpContext.updateSOPStatus(policeSopId, "Completed");
    }
  }, [policeReached]);

  useEffect(() => {
    if (fireReached) {
      httpContext.updateSOPStatus(fireSopId, "Completed");
    }
  }, [fireReached]);

  useEffect(() => {
    if (ambulanceReached) {
      httpContext.updateSOPStatus(ambulanceSopId, "Completed");
    }
  }, [ambulanceReached]);

  useEffect(() => {
    if (policeSopId) {
      let intervalId = setInterval(async () => {
        let status = await httpContext.verifyIncident(policeSopId);
        if (status && status.status === "Responded") {
          commonContext.setStartPolice(true);
        }
        if (status && status.status === "Completed") {
          setPoliceReached(true);
        }
        if (status && status.status === "Closed") {
          commonContext.setSOPStatus((prevStatus) => ({
            ...prevStatus,
            ["police"]: false,
          }));
          setPoliceClosed(true);
          clearInterval(intervalId);
        }
      }, 5000);
    }
  }, [policeSopId]);

  useEffect(() => {
    if (fireSopId) {
      let intervalId = setInterval(async () => {
        let status = await httpContext.verifyIncident(fireSopId);
        if (status && status.status === "Responded") {
          commonContext.setStartFire(true);
        }
        if (status && status.status === "Completed") {
          setFireReached(true);
        }
        if (status && status.status === "Closed") {
          commonContext.setSOPStatus((prevStatus) => ({
            ...prevStatus,
            ["fire"]: false,
          }));
          clearInterval(intervalId);
          setFireClosed(true);
        }
      }, 5000);
    }
  }, [fireSopId]);

  useEffect(() => {
    if (ambulanceSopId) {
      let intervalId = setInterval(async () => {
        let status = await httpContext.verifyIncident(ambulanceSopId);
        if (status && status.status === "Responded") {
          commonContext.setStartAmbulance(true);
        }
        if (status && status.status === "Completed") {
          setAmbulanceReached(true);
        }
        if (status && status.status === "Closed") {
          commonContext.setSOPStatus((prevStatus) => ({
            ...prevStatus,
            ["ambulance"]: false,
          }));
          clearInterval(intervalId);
          setAmbulanceClosed(true);
        }
      }, 5000);
    }
  }, [ambulanceSopId]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    commonContext.setSelectedType([]);
  };

  const closeVideo = () => {
    setCloseCCTV1(false);
    setCloseCCTV2(false);
  };

  const handleSubOptionChange = (opttype) => {
    setShowRouteMessageDone(false);
    setSelectedOptionType(opttype);
    if (opttype === "Po") {
      commonContext.setSelectedType(["police"]);
      addLayersToMap(["police"]);
      setPoliceLastTimestamp(0);
      cancelAnimationFrame(policeAnimationRef.current);
      setPoliceFrame(null);
    }
    if (opttype === "Po-Amb") {
      commonContext.setSelectedType(["police", "ambulance"]);
      addLayersToMap(["police", "ambulance"]);
      setPoliceLastTimestamp(0);
      setAmbulanceLastTimestamp(0);
      cancelAnimationFrame(policeAnimationRef.current);
      cancelAnimationFrame(ambulanceAnimationRef.current);
    }
    if (opttype === "Po-Amb-Fi") {
      commonContext.setSelectedType(["police", "fire", "ambulance"]);
      addLayersToMap(["police", "fire", "ambulance"]);
      setPoliceLastTimestamp(0);
      setAmbulanceLastTimestamp(0);
      cancelAnimationFrame(policeAnimationRef.current);
      cancelAnimationFrame(ambulanceAnimationRef.current);
      cancelAnimationFrame(fireTruckAnimationRef.current);
    }
    setViewState((prevViewState) => ({
      ...prevViewState,
      lat: 37.733413877011085,
      lng: -122.42932091193467,
      zoom: 15.5,
      pitch: 40,
    }));
  };
  const staticSizeScale = 6;

  function addLayersToMap(deployTypes) {
    setShowDispatch(false);
    loadScript(GOOGLE_MAPS_API_URL);
    timestamp += 0.02;
    const frame = policeTrips.map((trip) => trip.getFrame(timestamp));

    const interval = setInterval(() => {
      if (window.google) {
        clearInterval(interval);
        let iconData = [
          {
            name: "CCTV 1",
            coordinates: [37.783403877011085, -122.42420091193467],
            size: 40,
          },
          {
            name: "CCTV 2",
            coordinates: [37.783403877011085, -122.42450091193467],
            size: 40,
          },
        ];
        let accidentSpot = [
          {
            name: "Accident Spot",
            coordinates: [37.783403877011085, -122.42432091193467],
            size: 40,
          },
        ];
        let policeMarkers = [
          { name: "Police Station", coordinates: [37.783769005996376, -122.41291604880976], size: 40 },
          { name: "Police Station", coordinates: [37.780449929800014, -122.43232927238199], size: 40 },
          { name: "Police Station", coordinates: [37.77992870664791, -122.41889484390214], size: 40 },
        ];

        let ambulanceMarkers = [
          { name: "Police Station", coordinates: [37.79064300079298, -122.43201642046077], size: 40 },
          { name: "Police Station", coordinates: [37.78568097559007, -122.42167749080676], size: 40 },
          { name: "Police Station", coordinates: [37.78230181311466, -122.44278076213777], size: 40 },
        ];
        let fireMarkers = [
          { name: "Police Station", coordinates: [37.78671764632462, -122.41868871372044], size: 40 },
          { name: "Police Station", coordinates: [37.780387024090814, -122.43046618705746], size: 40 },
          { name: "Police Station", coordinates: [37.78982200693325, -122.4298372505656], size: 40 },
        ];

        let layer = [
          new TileLayer({
            id: "TileLayer",
            // data: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
            data: "https://a.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png",
            maxZoom: 36,
            minZoom: 0,

            renderSubLayers: (props) => {
              const { boundingBox } = props.tile;

              return new BitmapLayer(props, {
                data: null,
                image: props.data,
                bounds: [boundingBox[0][0], boundingBox[0][1], boundingBox[1][0], boundingBox[1][1]],
              });
            },
            pickable: true,
          }),
          new IconLayer({
            id: "IconLayer",
            data: iconData,
            getIcon: () => ({
              url: "/assets/cctv.png",
              width: 128,
              height: 128,
            }),
            getPosition: (d) => [d.coordinates[1], d.coordinates[0], 5],
            getSize: (d) => d.size,
            pickable: true,
            onClick: (info, event) => {
              if (info.object.name === "CCTV 2") {
                setCloseCCTV2(true);
                setCloseCCTV1(false);
              } else if (info.object.name === "CCTV 1") {
                setCloseCCTV2(false);
                setCloseCCTV1(true);
              }
            },
          }),
          new IconLayer({
            id: "IconLayer",
            data: accidentSpot,
            getIcon: () => ({
              url: "/assets/accident-spot.png",
              width: 128,
              height: 128,
            }),
            getPosition: (d) => [d.coordinates[1], d.coordinates[0], 15],
            getSize: (d) => d.size,
            pickable: true,
          }),
        ];
        setLayers(layer);
        var data;
        var markerData;
        var policeScenegraphLayer,
          searchAmbulanceLayer,
          searchFireLayer,
          searchLayer,
          ambulanceScenegraphLayer,
          fireTruckScenegraphLayer = [];
        deployTypes?.forEach((type) => {
          if (type === "police") {
            data = [...policeTrips];
            markerData = [...DATA_URL.policeVans];
            let markerPosition = [...DATA_URL.policeVans[0][0]];
            let policemarker = [{ name: "CCTV 1", coordinates: [-122.43232927238199, 37.780249929800014], size: 40 }];
            policeScenegraphLayer = [
              new ScenegraphLayer({
                id: "police-van",
                data: policeTrips.map((trip) => trip.getFrame(timestamp)),
                scenegraph: POLICE_MODEL_URL,
                sizeScale: 200,
                getPosition: (d) => d.point,
                getTranslation: [0, 0, 1],
                getOrientation: (d) => [0, 180 - d.heading, 90],
              }),
              new IconLayer({
                id: "IconLayer",
                data: policemarker,
                getIcon: () => ({
                  url: "/assets/police-station.png",
                  width: 128,
                  height: 128,
                }),
                getPosition: (d) => [d.coordinates[0], d.coordinates[1], 25],
                getSize: (d) => d.size,
                pickable: true,
              }),
            ];
            searchLayer = [
              new IconLayer({
                id: "search-layer",
                data: policeMarkers,
                getIcon: () => ({
                  url: "/assets/police-station.png",
                  width: 128,
                  height: 128,
                }),
                getPosition: (d) => [d.coordinates[1], d.coordinates[0], 25],
                getSize: (d) => d.size,
                pickable: true,
              }),
            ];
            // setLayers([...layers, ...searchLayer]);
          } else if (type === "ambulance") {
            data = [...data, ...ambulanceTrips];
            markerData = [...markerData, ...DATA_URL.ambulances];

            let markerPosition = [...DATA_URL.ambulances[0][0]];
            let ambulancemarker = [{ name: "CCTV 1", coordinates: [-122.42187749080676, 37.78588097559007], size: 40 }];
            ambulanceScenegraphLayer = [
              new ScenegraphLayer({
                id: "ambulance",
                data: ambulanceTrips.map((trip) => trip.getFrame(timestamp)),
                scenegraph: AMBULANCE_MODEL_URL,
                sizeScale: staticSizeScale,
                getPosition: (d) => d.point,
                getTranslation: [0, 0, 1],
                getOrientation: (d) => [0, 360 - d.heading, 90],
                getSize: (d) => staticSizeScale,
              }),
              new IconLayer({
                id: "IconLayer",
                data: ambulancemarker,
                getIcon: () => ({
                  url: "/assets/hospital.png",
                  width: 128,
                  height: 128,
                }),
                getPosition: (d) => [d.coordinates[0], d.coordinates[1], 25],
                getSize: (d) => d.size,
                pickable: true,
              }),
            ];
            searchAmbulanceLayer = [
              new IconLayer({
                id: "search-layer",
                data: ambulanceMarkers,
                getIcon: () => ({
                  url: "/assets/hospital.png",
                  width: 128,
                  height: 128,
                }),
                getPosition: (d) => [d.coordinates[1], d.coordinates[0], 25],
                getSize: (d) => d.size,
                pickable: true,
              }),
            ];
          } else if (type === "fire") {
            data = [...data, ...fireTruckTrips];
            markerData = [...markerData, ...DATA_URL.fireTrucks];
            let markerPosition = [...DATA_URL.fireTrucks[0][0]];
            let firemarker = [{ name: "CCTV 1", coordinates: [-122.43067618705746, 37.780387024090814], size: 40 }];

            fireTruckScenegraphLayer = [
              new ScenegraphLayer({
                id: "fire-truck",
                data: fireTruckTrips.map((trip) => trip.getFrame(timestamp)),
                scenegraph: FIRE_TRUCK_MODEL_URL,
                sizeScale: 5,
                getPosition: (d) => d.point,
                getTranslation: [0, 0, 1],
                getOrientation: (d) => [0, 360 - d.heading, 90],
              }),
              new IconLayer({
                id: "IconLayer",
                data: firemarker,
                getIcon: () => ({
                  url: "/assets/fire-station.png",
                  width: 128,
                  height: 128,
                }),
                getPosition: (d) => [d.coordinates[0], d.coordinates[1], 25],
                getSize: (d) => d.size,
                pickable: true,
              }),
            ];

            searchFireLayer = [
              new IconLayer({
                id: "search-layer",
                data: fireMarkers,
                getIcon: () => ({
                  url: "/assets/fire-station.png",
                  width: 128,
                  height: 128,
                }),
                getPosition: (d) => [d.coordinates[1], d.coordinates[0], 25],
                getSize: (d) => d.size,
                pickable: true,
              }),
            ];
          }
        });
        const startEndP = markerData?.map((trip) => [trip[0]]).flat();
        let pathlayer = [
          new PathLayer({
            id: "trip-lines",
            data: data,
            getPath: (d) => d.keyframes.map((f) => f.point),
            getColor: (_) => [10, 100, 255],
            jointRounded: true,
            opacity: 0.5,
            getWidth: 2,
          }),
        ];
        setShowRouteMessage(true);
        setViewState((prevViewState) => ({
          ...prevViewState,
          latitude: 37.78568097559007,
          longitude: -122.43129820502973,
          zoom: 14.5,
        }));
        setLayers([...(layer ?? []), ...(searchLayer ?? []), ...(searchAmbulanceLayer ?? []), ...(searchFireLayer ?? [])]);
        setTimeout(() => {
          setShowRouteMessageDone(true);
          setLayers((currentLayers) => currentLayers.filter((layer) => layer.id !== "search-layer"));
          setLayers([
            ...(layer ?? []),
            ...(pathlayer ?? []),
            ...(policeScenegraphLayer ?? []),
            ...(ambulanceScenegraphLayer ?? []),
            ...(fireTruckScenegraphLayer ?? []),
          ]);
          setViewState((prevViewState) => ({
            ...prevViewState,
            latitude: 37.78207547094779,
            longitude: -122.42841918423334,
            zoom: 15.5,
          }));
          setShowDispatch(true);
          setTimeout(() => {
            setShowRouteMessage(false);
            setShowRouteMessageDone(false);
          }, 8000);
        }, 5000);
      }
    }, 100);
  }
  function track(event) {
    if (event.target.value === "police") {
      if (commonContext.startPolice) {
        shouldCenter = true;
        startPoliceVanAnimation("police");
      } else {
        shouldCenter = false;
        setViewState((prevViewState) => ({
          ...prevViewState,
          latitude: 37.780449929800014,
          longitude: -122.43232927238199,
          zoom: 19,
        }));
      }
    } else if (event.target.value === "ambulance") {
      if (commonContext.startAmbulance) {
        shouldCenter = true;
        startPoliceVanAnimation("ambulance");
      } else {
        shouldCenter = false;
        setViewState((prevViewState) => ({
          ...prevViewState,
          latitude: 37.78568097559007,
          longitude: -122.42167749080676,
          zoom: 19,
        }));
      }
    } else if (event.target.value === "fire") {
      if (commonContext.startFire) {
        shouldCenter = true;
        startPoliceVanAnimation("fire");
      } else {
        shouldCenter = false;
        setViewState((prevViewState) => ({
          ...prevViewState,
          latitude: 37.780387024090814,
          longitude: -122.43046618705746,
          zoom: 19,
        }));
      }
    }
  }

  async function setPlacesName() {
    let plc = await httpContext.getLocation(mapCenter.lat, mapCenter.lng);
    if (plc) {
      setAccidentDetails({
        latitude: mapCenter.lat,
        longitude: mapCenter.lng,
        place: plc.display_name,
      });
    }
  }

  async function sopAction(event) {
    commonContext.setDisableDeploy(true);
    setWorkOrder(true);
    if (event.target.value === "deploy") {
      commonContext.selectedtype.forEach(async (type) => {
        if (type === "police") {
          let sopDetails = config.sop.sopAccidentPolice;
          let sopResponse = await httpContext.createSop(
            sopDetails.summary,
            sopDetails.details,
            sopDetails.masterCriticalityId,
            sopDetails.masterCategoryTypeId
          );
          if (sopResponse) setPoliceSopId(sopResponse.id);
        } else if (type === "fire") {
          let sopDetails = config.sop.sopAccidentFire;
          let sopResponse = await httpContext.createSop(
            sopDetails.summary,
            sopDetails.details,
            sopDetails.masterCriticalityId,
            sopDetails.masterCategoryTypeId
          );
          if (sopResponse) setFireSopId(sopResponse.id);
        } else if (type === "ambulance") {
          let sopDetails = config.sop.sopAccidentAmbulance;
          let sopResponse = await httpContext.createSop(
            sopDetails.summary,
            sopDetails.details,
            sopDetails.masterCriticalityId,
            sopDetails.masterCategoryTypeId
          );
          if (sopResponse) setAmbulanceSopId(sopResponse.id);
        }
      });
    } else {
      var sopId;
      let sopDetails = config.sop.sopAccidentPolice;
      let sopResponse = await httpContext.createSop(
        sopDetails.summary,
        sopDetails.details,
        sopDetails.masterCriticalityId,
        sopDetails.masterCategoryTypeId
      );
      if (sopResponse) sopId = sopResponse.id;
      let intervalId = setInterval(async () => {
        let status = await httpContext.verifyIncident(sopId);
        if (status && status.status === "Closed") {
          commonContext.setSOPStatus((prevStatus) => ({
            ...prevStatus,
            fileReport: false,
          }));
          clearInterval(intervalId);
        }
      }, 5000);
    }
  }

  function fetchSOP(url) {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {})
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }
  function openWindow() {
    setShowWindow(true);
  }

  function closeWindow() {
    setShowWindow(false);
    addLayersToMap(commonContext.selectedtype);
  }

  return (
    <div className="sop-panel p-3">
      <div>
        {!commonContext.showAnalytics && (
          <div className="row">
            <div className="col-7 p-2 position-relative">
              <div className="position-absolute z-2 puffloader">
                <ClipLoader className="mx-auto" size={100} color="black" loading={showRouteMessage && !showRouteMessageDone} />
                {showRouteMessage && !showRouteMessageDone && <div className="fw-bold fs-6">Searching for optimized route..</div>}
              </div>
              <div className={`mx-lg-2 position-relative mx-md-2 accident-map ${workOrder ? "map-height" : "map-height-full"}`}>
                <DeckGL
                  className="position-relative"
                  ref={mapRef}
                  initialViewState={INITIAL_VIEW_STATE}
                  controller={{ touchRotate: true, inertia: 250 }}
                  layers={layers}
                  viewState={viewState}
                  onViewStateChange={({ viewState }) => setViewState(viewState)}
                ></DeckGL>
              </div>
              {showRouteMessage && (
                <div className="p-3 fs-6 pt-4 position-absolute top-0 fw-bold">
                  {/* {!showRouteMessageDone && <div>Searching for optimized route..</div>} */}
                  {showRouteMessageDone && (
                    <div>
                      Route Optimization Done - Best Match Found -
                      {(commonContext.selectedtype[0] ? " Police Station " : "") +
                        (commonContext.selectedtype[1] ? ", Ambulance " : "") +
                        (commonContext.selectedtype[2] ? ", Fire Engine " : "")}
                      Identified
                    </div>
                  )}
                </div>
              )}
              {commonContext.selectedtype.length !== 0 && workOrder && (
                <div className="d-flex p-2">
                  <div className="fc-white">Track : </div>
                  <select className="track-dropdown" name="" id="" value={commonContext.trackOptiFon} onChange={track}>
                    <option className="fc-black" value="">
                      Track option
                    </option>
                    {trackdropdown
                      .filter((trk) => commonContext.selectedtype.includes(trk.key))
                      .map((trk) => (
                        <option key={trk.key} className="fc-black" value={trk.key}>
                          {trk.name}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              {workOrder && (
                <div className="mytabs p-2">
                  <div className="tabOptions">
                    <div>
                      <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                        Incidents
                      </button>
                    </div>
                    <div>
                      <button onClick={() => setActiveTab("Progress")} className={`tabBtns ${activeTab === "Progress" ? "active" : null}`}>
                        Progress
                      </button>
                    </div>
                    <div>
                      <th className="mini">
                        <img src="/maximize.png" alt="" className="maximize-accident" onClick={openWindow} />
                      </th>
                    </div>
                  </div>
                  <div className="tabContent ">
                    {activeTab === "Incidents" && <iframe className="sop-iframe" src={httpContext.sop} title="Sop"></iframe>}
                    {activeTab === "Progress" && (
                      <div>
                        <div className="set p-2">
                          {option3.map((opttype) =>
                            commonContext.selectedtype?.map((ty) => {
                              const getStartedStatus = (type) => {
                                switch (type) {
                                  case "police":
                                    return commonContext.startPolice;
                                  case "fire":
                                    return commonContext.startFire;
                                  case "ambulance":
                                    return commonContext.startAmbulance;
                                  default:
                                    return false;
                                }
                              };
                              const getReachedStatus = (type) => {
                                switch (type) {
                                  case "police":
                                    return policeReached;
                                  case "fire":
                                    return fireReached;
                                  case "ambulance":
                                    return ambulanceReached;
                                  default:
                                    return false;
                                }
                              };
                              const getClosedStatus = (type) => {
                                switch (type) {
                                  case "police":
                                    return policeclosed;
                                  case "fire":
                                    return fireClosed;
                                  case "ambulance":
                                    return ambulanceClosed;
                                  default:
                                    return false;
                                }
                              };

                              return (
                                ty === opttype.key && (
                                  <ProgressTracking
                                    key={ty}
                                    title={ty}
                                    label="accident"
                                    started={getStartedStatus(ty)}
                                    reached={getReachedStatus(ty)}
                                    closed={getClosedStatus(ty)}
                                  />
                                )
                              );
                            })
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className=" col-5 p-2">
              <div className="p-1 side-panel side-panel-h">
                <div className="m-1 p-2 deatils">
                  <div className="fs-5 fw-bold text-center">Incident Details</div>
                  <div className="fw-bold">
                    <div className="p-2">
                      Location : &nbsp;
                      <span className="fs-7 fw-lighter">
                        <a href={`https://www.google.com/maps/place/${accidentDetails.latitude},${accidentDetails.longitude}`}>
                          {accidentDetails.place}
                        </a>
                      </span>
                    </div>
                    <div className="p-2">
                      Coordinates : &nbsp;
                      <span className=" fw-lighter">
                        {accidentDetails.latitude}, {accidentDetails.longitude}
                      </span>
                    </div>
                    <div className="p-2">
                      Event : &nbsp;
                      <span className=" fw-lighter">Accident placed</span>
                    </div>
                    {/* {showRouteMessage && (
                      <div className="p-2">
                        {!showRouteMessageDone && <div>Searching for optimized route..</div>}
                        {showRouteMessageDone && (
                          <div>
                            Route Optimization Done - Best Match Found -
                            {(commonContext.selectedtype[0] ? " Police Station " : "") +
                              (commonContext.selectedtype[1] ? ", Ambulance " : "") +
                              (commonContext.selectedtype[2] ? ", Fire Engine " : "")}
                            Identified
                          </div>
                        )}
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
              {(closecctv1 || closecctv2) && (
                <div className="m-1 side-panel side-panel-h p-3">
                  <div className="fw-bold">{closecctv1 ? "CCTV 1" : closecctv2 ? "CCTV 2" : ""}</div>
                  <div className="cctv-window">
                    {closecctv1 && (
                      <video autoPlay muted loop width="100%" height="100%">
                        <source src="./assets/cctv.mp4" type="video/mp4" />
                      </video>
                    )}
                    {closecctv2 && (
                      <video autoPlay muted loop width="100%" height="100%">
                        <source src="./assets/cctv_1.mp4" type="video/mp4" />
                      </video>
                    )}
                  </div>
                </div>
              )}
              <div className="m-1 side-panel side-panel-h-down p-3">
                <div className="headertext fw-bold fs-6">Select Option for SOP</div>
                <div className="main-block">
                  {option1.map((option) => (
                    <div className="fs-7 p-1" key={option.value}>
                      <input
                        className="me-1"
                        type="radio"
                        id={option.value}
                        name="option1"
                        value={option.value}
                        checked={selectedOption === option.value}
                        onChange={() => handleOptionChange(option.value)}
                      />
                      <label htmlFor={option.value}>{option.label}</label>
                    </div>
                  ))}
                </div>
                {selectedOption === "option2" && (
                  <div className="p-2">
                    <div className="action p-2 ">
                      <div className=" fw-bold fs-6">Action</div>
                      <div className="d-flex fs-7 align-items-center justify-content-around">
                        <div>Create a new work order request (File Report)</div>
                        <div className="">
                          <button className="bttn btn fs-7" onClick={(event) => sopAction(event)}>
                            Work Order
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {selectedOption === "Deploy" && (
                  <div>
                    <div className="sub-block">
                      <div className="headertext fw-bold fs-6">Select Deploy Types</div>
                      <div className="radio">
                        {option2.map((type) => (
                          <div className="fs-7 p-1" key={type.value}>
                            <input
                              className="me-1"
                              disabled={commonContext.disableDeploy}
                              type="radio"
                              id={type.key}
                              name="Option2"
                              value={type.key}
                              checked={selectedOptionType === type.key}
                              onChange={() => handleSubOptionChange(type.key)}
                            />
                            <label htmlFor={type.key}>{type.name}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {commonContext.selectedtype.length !== 0 && showDispatch && (
                      <div className="m-2 action p-2  ">
                        <div className=" fw-bold fs-6">Action</div>
                        <div className="d-flex fs-7 align-items-center justify-content-around">
                          <div>Deploy </div>
                          <div className="">
                            <button className="bttn btn fs-7" value="deploy" onClick={(event) => sopAction(event)}>
                              Dispatch
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {showWindow && (
          <div className="ahu-analytics">
            <AccidentTabs closeWindow={closeWindow} active={activeTab} label={"AccidentTabs"} />
          </div>
        )}
      </div>
    </div>
  );

  function startPoliceVanAnimation(vehicleType) {
    let localFrame, setFrame, lastTimestamp, trips, layerId, animationRef;

    switch (vehicleType) {
      case "police":
        trips = policeTrips;
        layerId = "police-van";
        localFrame = policeFrame;
        lastTimestamp = policeLastTimestamp;
        animationRef = policeAnimationRef;
        setFrame = setPoliceFrame;
        break;
      case "ambulance":
        trips = ambulanceTrips;
        layerId = "ambulance";
        localFrame = ambulanceFrame;
        lastTimestamp = ambulanceLastTimestamp;
        animationRef = ambulanceAnimationRef;
        setFrame = setAmbulanceFrame;
        break;
      case "fire":
        trips = fireTruckTrips;
        layerId = "fire-truck";
        localFrame = fireFrame;
        lastTimestamp = fireTruckLastTimestamp;
        animationRef = fireTruckAnimationRef;
        setFrame = setFireFrame;
        break;
      default:
        return;
    }

    let timestamp = lastTimestamp || 0;

    const onAnimationFrame = () => {
      timestamp += 0.12;

      const frame = trips?.map((trip) => trip.getFrame(timestamp));
      const hasReachedDestination = trips?.every((trip, index) => {
        const lastKeyframe = trip.keyframes[trip.keyframes.length - 1];
        return frame[index].point[0] === lastKeyframe.point[0] && frame[index].point[1] === lastKeyframe.point[1];
      });

      if (option.tracking && shouldCenter && !hasReachedDestination) {
        setViewState((prev) => ({
          ...prev,
          latitude: frame[0].point[1],
          longitude: frame[0].point[0],
          bearing: frame[0].heading,
          zoom: 18,
        }));
      }
      //   if (option.tracking && shouldCenter && !hasReachedDestination) {
      //     gmap?.moveCamera({
      //       center: { lat: frame[0].point[1], lng: frame[0].point[0] },
      //       heading: frame[0].heading,
      //     });
      //   }
      //   mapRef.current.setProps({
      //     layers: mapRef.current.props.layers.map((layer) => (layer?.id === layerId ? layer.clone({ data: frame }) : layer)),
      //   });

      setLayers((prevLayers) => prevLayers.map((layer) => (layer?.id === layerId ? layer.clone({ data: frame }) : layer)));
      if (hasReachedDestination) {
      }

      setReachedDestination(destinationStatus);

      switch (vehicleType) {
        case "police":
          policeAnimationRef.current = requestAnimationFrame(onAnimationFrame);
          setPoliceLastTimestamp(timestamp);
          if (hasReachedDestination) {
            setPoliceReached(true);
          }
          break;
        case "ambulance":
          ambulanceAnimationRef.current = requestAnimationFrame(onAnimationFrame);
          setAmbulanceLastTimestamp(timestamp);
          if (hasReachedDestination) {
            setAmbulanceReached(true);
          }
          break;
        case "fire":
          fireTruckAnimationRef.current = requestAnimationFrame(onAnimationFrame);
          setFireTruckLastTimestamp(timestamp);
          if (hasReachedDestination) {
            setFireReached(true);
          }
          break;
        default:
          break;
      }
    };

    onAnimationFrame();

    return () => {
      switch (vehicleType) {
        case "police":
          cancelAnimationFrame(policeAnimationRef.current);
          break;
        case "ambulance":
          cancelAnimationFrame(ambulanceAnimationRef.current);
          break;
        case "fireTruck":
          cancelAnimationFrame(fireTruckAnimationRef.current);
          break;
        default:
          break;
      }
    };
  }
};

export default Accident;

function loadScript(url) {
  if (typeof google !== "undefined") {
    return Promise.resolve();
  }
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.onload = resolve;
    document.head.appendChild(script);
  });
}
