/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { HttpServiceContext } from "../../config/httpContext";
import { CommonServiceContext } from "../../config/commonContext";

function BarChart(props) {
  const { buildingId, data, selectedDate, selectedtime, style, heading } = props;
  const [chartData, setChartData] = useState([]);
  const [option, setOption] = useState({});
  const [xlabel, setxlabel] = useState();
  const [chartType, setchartType] = useState("bar");

  const httpContext = useContext(HttpServiceContext);
  const commonContext = useContext(CommonServiceContext);
  function handlechartType(event) {
    setchartType(event.target.value);
  }

  async function getBmsAlarmsData() {
    let dt = await httpContext.getBmsAlarmsDataCount(data.type, null, null, null, buildingId, data.order, data.limit ?? data.limit);
    if (dt) {
      setChartData(dt.data);
    }
  }

  async function getTrafficData() {
    let dt = await httpContext.getTrafficVolumneByCategory(data.type, data.datatype, selectedDate, selectedtime);
    if (dt) {
      setChartData(dt.data);
    }
  }
  useEffect(() => {
    if (data.apicategory === "timeseries" || data.apicategory === "frequency") {
      getBmsAlarmsData();
    }
    if (data?.key === "traffic") {
      if (data.column === "street") {
        getTrafficData();
      } else {
        setChartData(data.data);
        setxlabel(data.xData);
      }
    }
    if (data.key === "LI" || data.key === "wtr") {
      setChartData(data.data);
      setxlabel(data.xLabel);
    }
  }, [buildingId, data, selectedDate, selectedtime]);

  useEffect(() => {
    let newOption;
    const livabilityTitles = [
      "Pillars for Liveability Index",
      "Pillars for Citizen Perception",
      "Pillars for Economic Ability",
      "Pillars for Quality Of Life",
      "Pillars for Sustainability",
    ];

    const isLivabilityTitle = livabilityTitles.includes(data.title);
    if (chartType === "bar") {
      newOption = {
        color:
          data?.title === "Average Traffic Volume By Category"
            ? [
                {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: commonContext.colorStops.redYellowGreen,
                },
              ]
            : isLivabilityTitle
            ? ["#32CD32", "#1E90FF", "#FFD700"]
            : [
                {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: commonContext.colorStops.cyanBlue,
                },
              ],

        title: {
          text: data?.title ?? "",

          textStyle: {
            color: "#ffffff",
            fontSize: 12,
          },
          textAlign: "left",
          left: "3%",
          top: "1%",
        },

        tooltip: {},
        xAxis: {
          type: "category",
          data: xlabel ?? chartData?.map((item) => item[0]),
          axisLabel: {
            color: "white",
            fontSize: 8,
            interval: 0,
            rotate: data?.key === "LI" ? 0 : 30,
          },
          show: chartType === "bar",
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "white",
            fontSize: 8,
          },
          splitLine: {
            show: false,
          },
        },
        grid: {
          top: 40,
          left: 40,
          right: 15,
          height: data.height ?? "62%",
        },
        series:
          data.key === "LI" || data.key === "wtr"
            ? chartData?.map((ch) => ({
                name: ch.name,
                type: "bar",
                data: ch.data,
              }))
            : [
                {
                  type: chartType,
                  radius: "55%",
                  center: ["50%", "42%"],
                  labelLine: {
                    show: false,
                  },

                  label: {
                    position: "inside",
                    formatter: "{c}",
                    color: "white",
                    fontSize: 10,
                  },
                  data: chartData?.map((item) => ({
                    value: item[1],
                    name: item[0],
                  })),
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                  },
                },
              ],
      };
    } else if (chartType === "pie") {
      newOption = {
        color:
          data?.title === "Average Traffic Volume By Category"
            ? [
                {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: commonContext.colorStops.redYellowGreen,
                },
              ]
            : [
                {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: commonContext.colorStops.cyanBlue,
                },
              ],
        title: {
          text: data?.title ?? null,
          textStyle: {
            color: "#ffffff",
            fontSize: 10,
          },
          textAlign: "left",
          left: "3%",
          top: "1%",
        },

        tooltip: {
          trigger: "item",
          confine: true,
        },
        legend: {
          top: "80%",
          itemHeight: 10,
          itemWidth: 15,
          orient: "horizontal",
          bottom: "center",
          textStyle: {
            fontSize: 8,
            color: "white",
          },
        },
        // show: chartType === "bar",
        xAxis: {
          show: false,
        },
        series: [
          {
            type: chartType,
            radius: "55%",
            center: ["50%", "42%"], // Centering the pie chart vertically and horizontally
            labelLine: {
              show: false,
            },

            label: {
              position: "inside",
              formatter: "{c}",
              color: "white",
              fontSize: 10,
            },
            data: chartData?.map((item) => ({
              value: item[1],
              name: item[0],
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    }
    setOption(newOption);
  }, [chartData, chartType]);

  const onEvents = (chart) => {
    chart.on("click", (event) => commonContext.handleChartClick(event.name, data));
  };

  return (
    <div className="chart-container mt-2">
      {data?.title === " `${livabilitydata?.source[0].name}`" && (
        <select className="dropdown" value={chartType} onChange={handlechartType}>
          <option value="pie" className="opt">
            Pie
          </option>
          <option value="bar" className="opt">
            Bar
          </option>
        </select>
      )}
      <ReactECharts option={option} onChartReady={onEvents} style={style} />
    </div>
  );
}

export default BarChart;
