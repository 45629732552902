/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsSolidGauge from "highcharts/modules/solid-gauge";
import Variablepie from "highcharts/modules/variable-pie";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import { useState } from "react";
import { HttpServiceContext } from "../../config/httpContext";
import { CommonServiceContext } from "../../config/commonContext";
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
Variablepie(Highcharts);
HighchartsHeatmap(Highcharts);

function Heatmap(props) {
  const { data, filter, click } = props;
  const [chartData, setChartData] = useState();

  const httpContext = useContext(HttpServiceContext);
  const commonContext = useContext(CommonServiceContext);

  async function getBmsAlarmsDataCount() {
    let dt = await httpContext.getBmsAlarmsColumnDataCount(data.xaxis, data.yaxis, filter[0], filter[1], filter[2]);
    if (dt) {
      setChartData(dt.data);
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (data.apicategory === "timeseries") {
        getBmsAlarmsDataCount();
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [filter[0], filter[1], filter[2]]);

  useEffect(() => {
    if (chartData) {
      const xlabel = [...new Set(chartData.map((item) => item[0]))];
      const ylabel = [...new Set(chartData.map((item) => item[1]))];

      const heatmapData = [];
      xlabel.forEach((xItem, xIndex) => {
        ylabel.forEach((yItem, yIndex) => {
          const found = chartData.find((item) => item[0] === xItem && item[1] === yItem);
          if (found) {
            heatmapData.push({
              x: xIndex,
              y: yIndex,
              value: found[2],
            });
          } else {
            heatmapData.push({
              x: xIndex,
              y: yIndex,
              value: 0,
            });
          }
        });
      });

      const chart = Highcharts.chart(data.id, {
        chart: {
          type: "heatmap",
          marginTop: 50,
          marginRight: 35,
          backgroundColor: "hsla(202, 87%, 53%, 0.16)",
        },

        title: {
          text: data.title,
          style: {
            fontSize: "12px",
            color: "#fff",
          },
          floating: true,
          align: "left",
          x: 50,
          y: 10,
        },
        xAxis: {
          categories: xlabel,
          title: {
            text: data.xaxis,
            style: {
              fontSize: "12px",
              color: "#fff",
            },
            x: -15,
            y: 0,
          },
          labels: {
            x: 10,
            y: 20,
            style: { color: "#FFFFFF", fontSize: 10 },
          },
        },
        yAxis: {
          categories: ylabel,
          title: {
            text: data.yaxis,
            style: {
              fontSize: "12px",
              color: "#fff",
            },
            x: 0,
            y: 5,
          },
          labels: {
            x: -10,
            y: 5,
            style: { color: "#FFFFFF", fontSize: 10 },
          },
          reversed: true,
        },
        credits: {
          enabled: false,
        },
        legend: {
          align: "center",
          margin: 0,
          y: 25,
        },
        accessibility: {
          enabled: false,
        },
        colorAxis: {
          min: 0,
          minColor: "#FFFFFF",
          maxColor: Highcharts.getOptions().colors[7],
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.series.xAxis.categories[this.point.x] +
              "</b> sold<br>" +
              "<b>" +
              this.point.value +
              "</b> items on <br>" +
              "<b>" +
              this.series.yAxis.categories[this.point.y] +
              "</b>"
            );
          },
        },
        series: [
          {
            name: "Sales per employee",
            borderWidth: 1,
            data: heatmapData,
            dataLabels: {
              enabled: true,
              color: "#000000",
            },
            point: {
              events: {
                click: function (params) {
                  commonContext.handleChartClick(params, data, filter);
                },
              },
            },
          },
        ],
      });
      if (data.advId) {
        chart.renderer
          .button(
            data.button,
            chart.plotWidth + chart.plotLeft - 100, // x position
            chart.plotTop - 40, // Y position
            function () {
              click(data.advId);
            },
            {
              fill: "#337ab7",
              style: {
                color: "white",
                fontWeight: "bold",
              },
              states: {
                hover: {
                  fill: "#135296",
                },
              },
            }
          )
          .add();
      }
    }
  }, [data.id, chartData]);

  return <div id={data.id} style={{ width: "46vw", height: "60vh" }}></div>;
}

export default Heatmap;
