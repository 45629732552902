import React, { useState, useEffect } from "react";
import StepProgressBar from "react-step-progress";
import "react-step-progress/dist/index.css";

function ProgressTracking(props) {
  const { title, label, started, reached, closed } = props;
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (started) {
      setCurrentStep(2);
    } else {
      setCurrentStep(1);
    }
  }, [started]);
  useEffect(() => {
    if (reached) {
      setCurrentStep(3);
    } else {
      setCurrentStep(1);
    }
  }, [reached]);

  useEffect(() => {
    if (closed) {
      label === "aqi" ? setCurrentStep(2) : setCurrentStep(4);
    } else {
      setCurrentStep(1);
    }
  }, [closed]);

  const steps = [
    {
      label: "Created",
      name: "step 1",
    },
    {
      label: "Notified",
      name: "step 2",
    },
    {
      label: "Responded/Acknowledged",
      name: "step 3",
    },
    {
      label: "Completed",
      name: "step 4",
    },
    {
      label: "Closed",
      name: "step 5",
    },
  ];

  const aqiSteps = [
    {
      label: "Created",
      name: "step 1",
    },
    {
      label: "Notified - construction activities detected in the vicinity",
      name: "step 2",
    },
    {
      label: "Closed",
      name: "step 3",
    },
  ];

  return (
    <div className="m-2 fc-white row align-items-center">
      <div className="col-1">{title}</div>
      <div className="col-11">
        <StepProgressBar key={currentStep} startingStep={currentStep} steps={label === "aqi" ? aqiSteps : steps} />
      </div>
    </div>
  );
}

export default ProgressTracking;
