/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { HttpServiceContext } from "../../config/httpContext";
import { CommonServiceContext } from "../../config/commonContext";

const AreaChart = (props) => {
  const { data, style } = props;
  const [chartData, setChartData] = useState([]);
  const [option, setOption] = useState({});

  const httpContext = useContext(HttpServiceContext);
  const commonContext = useContext(CommonServiceContext);

  async function getTrafficData() {
    let dt = await httpContext.getTrafficDataByDay();
    if (dt) {
      setChartData(dt.data);
    }
  }

  useEffect(() => {
    if (data?.key === "traffic") {
      getTrafficData();
    }
  }, [data]);

  useEffect(() => {
    var ymin, ymax;
    if (data?.key !== "traffic") {
      ymin = Math.floor(Math.min(...chartData));
      ymax = Math.ceil(Math.max(...chartData));
    } else {
      ymin = 350000;
    }
    const newOption = {
      color: [
        {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: commonContext.colorStops.cyanBlue,
        },
      ],
      title: {
        text: data?.title ? data?.title : "Average Monthly Energy Consumption",
        textStyle: {
          color: "#ffffff",
          fontSize: 10,
        },
        textAlign: "left",
        left: "3%",
        top: "1%",
      },
      tooltip: {
        trigger: "item",
        responsive: true,
        confine: true,
      },
      legend: {
        data: ["category"],
        textStyle: {
          color: "#ffffff",
        },
      },
      xAxis: {
        type: "category",
        data: chartData?.map((item) => item[0]),
        axisLabel: {
          color: "white",
          fontSize: 8,
        },
        title: data?.key === "traffic" ? "Vehicle Count" : "Energy Consumption (kWh)",
      },
      yAxis: {
        type: "value",
        axisLabel: {
          color: "white",
          fontSize: 8,
        },
        splitLine: {
          show: false,
        },
        min: ymin,
        max: ymax,
      },
      grid: {
        top: 40,
        left: 40,
        right: 15,
        height: "62%",
      },
      series: [
        {
          name: data?.key === "traffic" ? "Vehicle Count" : "Energy Consumption",
          type: "line",
          areaStyle: {},
          data: chartData?.map((item) => item[1]),
        },
      ],
    };
    setOption(newOption);
  }, [chartData]);

  const onEvents = (chart) => {
    chart.on("click", (event) => commonContext.handleChartClick(event.name, data));
  };

  return (
    <div className="chart-container mt-2">
      <ReactECharts option={option} onChartReady={onEvents} style={style} />
    </div>
  );
};

export default AreaChart;
