/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import geojsonData from "../../assets/geojson.json";

const containerStyle = {
  width: "100%",
  height: "100vh",
};
const center = {
  lng: 51.5257,
  lat: 25.2845,
};
const zoom = 17;
const mapOptions = {
  disableDefaultUI: true,
  mapTypeId: "satellite",
};

function Gmap({ show3dView }) {
  const [map, setMap] = useState(null);
  const [infowindow, setInfoWindow] = useState(null);
  const [ahuIncident, setAhuIncident] = useState(false);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDMlVAALBCx-2tliIzQKST8qFwIyCvIRc4",
  });

  window.addEventListener("message", (event) => {
    if (event.data) {
      setAhuIncident(event.data.state);
    }
  });

  useEffect(() => {
    if (map) {
      const dataLayer = new window.google.maps.Data();
      dataLayer.addGeoJson(geojsonData);
      dataLayer.setMap(map);

      dataLayer.setStyle((feature) => {
        if (feature.Fg.name === "T07") {
          const featureColor = ahuIncident ? "red" : feature.Fg.fill;
          return {
            strokeColor: featureColor,
            fillColor: featureColor,
            strokeWeight: 2,
            fillOpacity: 0.6,
          };
        } else {
          return {
            strokeColor: feature.Fg.fill,
            fillColor: feature.Fg.fill,
            strokeWeight: 2,
            fillOpacity: 0.6,
          };
        }
      });
      dataLayer.addListener("click", (event) => {
        const name = event.feature.getProperty("name");

        let data = "";
        if (name === "T07") {
          data = "T07";
        }

        window.parent.postMessage(data, "*");

        const contentString = `
          <div style="
            width: 150px;
            background: hsla(219, 59%, 22%, 1);
            padding: 10px;
            border-radius: 8px;
            font-family: Arial, sans-serif;
            font-size: 12px;
            color: white;
          ">
            <div style="
              text-align: center;
              margin-bottom: 5px;
            ">
              ${name}
            </div>
            <div>
              <button id="launchBtn" style="
                width: 100%;
                background: hsla(219, 59%, 22%, 1);
                color: white;
                border: solid red;
                padding: 10px;
                border-radius: 4px;
                cursor: pointer;
              ">
                Launch Digital Twin
              </button>
            </div>
          </div>
        `;

        if (infowindow) {
          infowindow.close();
        }

        const newInfowindow = new window.google.maps.InfoWindow({
          content: contentString,
          position: event.latLng,
        });

        setInfoWindow(newInfowindow);
        newInfowindow.open(map);

        window.google.maps.event.addListenerOnce(newInfowindow, "domready", () => {
          document.getElementById("launchBtn").addEventListener("click", () => {
            let data = "";
            window.parent.postMessage(data, "*");
            if (name === "T07") {
              let data = "T07";
              window.parent.postMessage(data, "*");
              launchDigitalTwin();
            } else {
              let data = "";
              window.parent.postMessage(data, "*");
            }
          });
        });

        setTimeout(() => {
          newInfowindow.close();
        }, 5000);
      });
      map.addListener("click", (event) => {
        if (infowindow) {
          infowindow.close();
        }
        map.panTo(center);
      });
    }
  }, [ahuIncident]);

  const onLoad = useCallback(
    (map) => {
      setMap(map);
      const dataLayer = new window.google.maps.Data();
      dataLayer.addGeoJson(geojsonData);
      dataLayer.setMap(map);

      dataLayer.setStyle((feature) => {
        return {
          strokeColor: feature.Fg.fill,
          fillColor: feature.Fg.fill,
          strokeWeight: 2,
          fillOpacity: 0.6,
        };
      });

      dataLayer.addListener("click", (event) => {
        const name = event.feature.getProperty("name");

        let data = "";
        if (name === "T07") {
          data = "T07";
        }

        window.parent.postMessage(data, "*");

        const contentString = `
          <div style="
            width: 150px;
            background: hsla(219, 59%, 22%, 1);
            padding: 10px;
            border-radius: 8px;
            font-family: Arial, sans-serif;
            font-size: 12px;
            color: white;
          ">
            <div style="
              text-align: center;
              margin-bottom: 5px;
            ">
              ${name}
            </div>
            <div>
              <button id="launchBtn" style="
                width: 100%;
                background: hsla(219, 59%, 22%, 1);
                color: white;
                border: solid red;
                padding: 10px;
                border-radius: 4px;
                cursor: pointer;
              ">
                Launch Digital Twin
              </button>
            </div>
          </div>
        `;

        if (infowindow) {
          infowindow.close();
        }

        const newInfowindow = new window.google.maps.InfoWindow({
          content: contentString,
          position: event.latLng,
        });

        setInfoWindow(newInfowindow);
        newInfowindow.open(map);

        window.google.maps.event.addListenerOnce(newInfowindow, "domready", () => {
          document.getElementById("launchBtn").addEventListener("click", () => {
            if (name === "T07") {
              let data = "T07";
              window.parent.postMessage(data, "*");
              launchDigitalTwin();
            } else {
              let data = "";
              window.parent.postMessage(data, "*");
            }
          });
        });

        setTimeout(() => {
          newInfowindow.close();
        }, 5000);
      });

      map.addListener("click", (event) => {
        if (infowindow) {
          infowindow.close();
        }
        map.panTo(center);
      });
    },
    [infowindow]
  );

  function launchDigitalTwin() {
    let googledata = { message: "Launch Digital Twin" };
    window.parent.postMessage(googledata, "*");
  }
  const marker2Position = {
    lat: 25.2840720728976,
    lng: 51.52694333241061,
  };

  const onUnmount = useCallback(() => {
    setMap(null);
    setInfoWindow(null);
  }, []);

  if (loadError) {
    return <div>Error loading map</div>;
  }

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom} options={mapOptions} onLoad={onLoad} onUnmount={onUnmount}>
      {/* {ahuIncident && geojsonData.features.map((feature) => feature.properties.name === "T07") ? (
        <Marker position={center} icon={{ url: "../omnific_dashboards/public/assets/alert.png", scaledSize: new window.google.maps.Size(35, 45) }} />
      ) : (
        <Marker position={center} />
      )} */}
      {ahuIncident && geojsonData.features.map((feature) => feature.properties.name === "T07") && <Marker position={marker2Position} title="alert" />}
    </GoogleMap>
  ) : (
    <div>Loading...</div>
  );
}

export default Gmap;
