import React, { useContext, useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { CommonServiceContext } from "../../config/commonContext";

function LineChartStatic(props) {
  const { data } = props;
  const [chartData, setChartData] = useState([]);
  const [option, setOption] = useState({});

  const commonContext = useContext(CommonServiceContext);

  useEffect(() => {
    setChartData(data.data);
  }, []);
  useEffect(() => {
    const newOption = {
      title: {
        text: data?.title,
        textStyle: {
          color: "#ffffff",
          fontSize: 10,
        },
        textAlign: "left",
        left: "3%",
        top: "1%",
      },
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        data: data.xData,
        axisLabel: {
          color: "white",
          fontSize: 8,
        },
      },
      yAxis: {
        min: data.min,
        max: data.max,
        type: "value",
        axisLabel: {
          color: "white",
          fontSize: 8,
        },
        splitLine: {
          show: false,
        },
      },
      grid: {
        top: 40,
        left: 40,
        right: 15,
        height: "60%",
      },
      visualMap: {
        show: false,
        top: 50,
        right: 20,
        pieces: [
          {
            gt: 0,
            lte: 16,
            color: "#7fdb88",
          },
          {
            gt: 16,
            lte: 22,
            color: commonContext.colorStops.lightBlueViolet[0].color,
          },
          {
            gt: 22,
            lte: 40,
            color: "#e36266",
          },
        ],
        outOfRange: {
          color: commonContext.colorStops.lightBlueViolet[0].color,
        },
        lineStyle: {
          color: commonContext.colorStops.lightBlueViolet[0].color,
        },
      },
      series:
        data.key === "deltaT"
          ? [
              {
                name: data.column,
                type: "line",
                itemStyle: {
                  color: commonContext.colorStops.lightBlueViolet[0].color,
                },
                markPoint: chartData.map((e) =>
                  e < 22 ? commonContext.colorStops.lightBlueViolet[0].color : commonContext.colorStops.redOrange[0].color
                ),
                markLine: {
                  data: [
                    {
                      yAxis: data.Low ?? 0,
                      name: "Max Line 16",
                      label: {
                        formatter: "16",
                      },
                      lineStyle: {
                        type: "dashed",
                        color: "green",
                        width: 2,
                      },
                    },
                    {
                      yAxis: data.High ?? 0,
                      name: "Max Line 22",
                      label: {
                        formatter: "22",
                      },
                      lineStyle: {
                        type: "dashed",
                        color: "green",
                        width: 2,
                      },
                    },
                  ],
                },
                data: chartData,
              },
            ]
          : [
              {
                name: data.column,
                type: "line",
                itemStyle: {
                  color: commonContext.colorStops.lightBlueViolet[0].color,
                },
                markPoint: chartData.map((e) =>
                  e < 22 ? commonContext.colorStops.lightBlueViolet[0].color : commonContext.colorStops.redOrange[0].color
                ),

                data: chartData,
              },
            ],
    };
    setOption(newOption);
  }, [chartData, data]);
  return (
    <div className="chart-container mt-2">
      <ReactECharts option={option} style={{ height: "33vh", width: "100%" }} />
    </div>
  );
}

export default LineChartStatic;
