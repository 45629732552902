import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

function Radar(props) {
  const { data, style } = props;
  const [option, setOption] = useState({});

  useEffect(() => {
    if (data) {
      const newOption = {
        title: {
          text: data.title,
          textStyle: {
            color: "#ffffff",
            fontSize: 12,
          },
          textAlign: "left",
          left: "3%",
          top: "1%",
        },
        tooltip: {
          trigger: "item",
        },
        radar: {
          indicator: data.indicator.map((item) => ({
            name: item.name,
            max: item.max,
          })),
        },
        series: [
          {
            name: "Pollutant Levels",
            type: "radar",
            data: [
              {
                value: data.data[0].value,
                name: data.data.city,
              },
            ],
          },
        ],
      };

      setOption(newOption);
    }
  }, [data]);

  return (
    <div className="chart-container mt-2">
      <ReactECharts option={option} style={style} />
    </div>
  );
}

export default Radar;
