/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import ReactECharts from "echarts-for-react";
import { CommonServiceContext } from "../../config/commonContext";

function DougnutChart(props) {
  const { data, style, buildingId } = props;
  const [option, setOption] = useState({});
  const [chartData, setChartData] = useState();
  const [chartType, setchartType] = useState("pie");
  const commonContext = useContext(CommonServiceContext);

  function handlechartType(event) {
    setchartType(event.target.value);
  }
  useEffect(() => {
    if (data.key === "bId") {
      if (buildingId === "T07") {
        setChartData(data.data1);
      } else if (buildingId === "SSIF") {
        setChartData(data.data2);
      } else if (buildingId === "R01") {
        setChartData(data.data3);
      } else if (buildingId === "U07") {
        setChartData(data.data2);
      } else if (buildingId === "M") {
        setChartData(data.data1);
      } else if (buildingId === "FGL") {
        setChartData(data.data3);
      } else if (buildingId === "R02") {
        setChartData(data.data2);
      } else {
        setChartData(data.data);
      }
    } else {
      setChartData(data.data);
    }
  }, [buildingId, data]);

  useEffect(() => {
    let newOption;

    if (chartType === "pie") {
      newOption = {
        color: [
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: commonContext.colorStops.blueShade,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: commonContext.colorStops.greenShade,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: commonContext.colorStops.redShade,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: commonContext.colorStops.yellowShade,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: commonContext.colorStops.pinkPurple,
          },
        ],
        grid: {
          top: "10%",
          left: "3%",
          right: "4%",
          bottom: "3%",
        },
        legend: {
          bottom: 0,
          left: "center",
          textStyle: {
            color: "#ffffff",
            fontSize: 10,
          },
        },
        xAxis: {
          show: false,
        },
        tooltip: {
          trigger: "item",
        },
        title: {
          text: data?.title ?? "",
          textStyle: {
            color: "#ffffff",
            fontSize: 12,
          },
          textAlign: "left",
          left: "3%",
          top: "1%",
        },
        series: [
          {
            type: "pie",
            radius: [data.radius[0], data.radius[1]],
            center: [data.center[0], data.center[1]],
            startAngle: data.startAngle,
            endAngle: data.endAngle,
            labelLine: {
              show: false,
            },
            label: {
              color: "#FFF",
              fontSize: "20",

              position: "center",
              formatter: "{d}",
            },

            data: chartData || [0],
          },
        ],
      };
    } else if (chartType === "bar") {
      newOption = {
        color: [
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: commonContext.colorStops.blueShade,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: commonContext.colorStops.greenShade,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: commonContext.colorStops.pinkPurple,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: commonContext.colorStops.yellowShade,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: commonContext.colorStops.blueViolet,
          },
        ],
        grid: {
          left: "3%",
          right: "4%",
          bottom: 10,
          top: "15%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        title: {
          text: data?.title ?? "",
          textStyle: {
            color: "#ffffff",
            fontSize: 10,
          },
          textAlign: "left",
          left: "3%",
          top: "1%",
        },
        legend: {
          data: data?.data.map((item) => item.name) || [],
          textStyle: {
            color: "white",
            fontSize: 10,
          },
          left: "center",
          top: "75%",
        },
        xAxis: {
          type: "category",
          data: data?.data.map((item) => item.name) || [],
          axisLabel: {
            color: "white",
            fontSize: 10,
          },
          show: chartType === "bar",
        },
        yAxis: {
          // type:,
          axisLabel: {
            color: "white",
            fontSize: 8,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            type: "bar",

            labelLine: {
              show: false,
            },

            data: chartData || [0],
          },
        ],
      };
    }

    setOption(newOption);
  }, [chartData, data, chartType]);

  return (
    <div className="chart-container mt-2">
      <select className="dropdown" value={chartType} onChange={handlechartType}>
        <option value="pie" className="opt">
          Dial
        </option>
        <option value="bar" className="opt">
          Bar
        </option>
      </select>
      <ReactECharts option={option} style={style} />
    </div>
  );
}

export default DougnutChart;
