/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import axios from "axios";
import config from "../config.json";
export const HttpServiceContext = React.createContext();

const sopGeneric = config.sop.sopGeneric;
const sop = config.react.sopUrl;
const apiUrl = config.react.apiBaseUrl;
const trafficIframeURL = `/deckgl-traffic/traffic.html?apiUrl=${apiUrl}`;
const visioWebURL = `/VisioWeb-1.16.5-20240628/application/mapviewer-uikit/mapviewer.html?hash=kfef2d90dadc585d4f0657d84feb57d1a866f7800#?apiUrl=${apiUrl}`;

export const HttpServiceProvider = ({ children }) => {
  const [simulationUrl, setSimulationUrl] = useState(apiUrl);

  async function getBmsAlarmsDataCount(type, assetid, severity, classname, buildingId, order, limit) {
    let url = `${apiUrl}/bms/alarms/data/count?type=${type}`;
    if (assetid) url += `&assetid=${assetid}`;
    if (severity) url += `&severity=${severity}`;
    if (classname) url += `&classname=${classname}`;
    if (buildingId) url += `&buildingId=${buildingId}`;
    if (order) url += `&order=${order}`;
    if (limit) url += `&limit=${limit}`;

    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getFrequencyOfAssetsAll(type, assetid, severity, classname, value, entiredatavalue, buildingId) {
    let url = `${apiUrl}/bms/alarms/frequency/column/all?type=${type}`;
    if (assetid) {
      url += `&assetid=${assetid}`;
    }
    if (severity) {
      url += `&severity=${severity}`;
    }
    if (classname) {
      url += `&classname=${classname}`;
    }
    if (value) {
      url += `&value=${value}`;
    }
    if (entiredatavalue) {
      url += `&entireData=${entiredatavalue}`;
    }
    if (buildingId) {
      url += `&buildingID=${buildingId}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getBmsAlarmsColumnDataCount(xaxis, yaxis, assetid, severity, classname, buildingId) {
    let url = `${apiUrl}/bms/alarms/columns/data/count?xaxis=${xaxis}&yaxis=${yaxis}`;
    if (assetid) url += `&assetid=${assetid}`;
    if (severity) url += `&severity=${severity}`;
    if (classname) url += `&classname=${classname}`;
    if (buildingId) url += `&buildingId=${buildingId}`;
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }
  async function getFrequencyOfyearmonth(assetid, severity, classname) {
    let url = `${apiUrl}/bms/alarms/frequency/yearmonth?`;
    if (assetid) {
      url += `&assetid=${assetid}`;
    }
    if (severity) {
      url += `&severity=${severity}`;
    }
    if (classname) {
      url += `&classname=${classname}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getTimeseriesOfColumnsValue(type, value, assetid, severity, classname) {
    let url = `${apiUrl}/bms/alarms/timeseries/columnvalue?type=${type}&value=${value}`;
    if (assetid) {
      url += `&assetid=${assetid}`;
    }
    if (severity) {
      url += `&severity=${severity}`;
    }
    if (classname) {
      url += `&classname=${classname}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getTimeseriesColumnData(type, assetid, severity, classname) {
    let url = `${apiUrl}/bms/alarms/data?type=${type}`;
    if (assetid) {
      url += `&assetid=${assetid}`;
    }
    if (severity) {
      url += `&severity=${severity}`;
    }
    if (classname) {
      url += `&classname=${classname}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function gettrafficheatmap() {
    let url = `${apiUrl}/traffic/heatmap`;
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getTrafficDataByHour(selectedDate) {
    let url = `${apiUrl}/traffic/hour?`;

    if (selectedDate) {
      const [day, month, year] = selectedDate.split("-");
      const formattedDate = `${year}-${month}-${day}`;
      url += `&date=${formattedDate}`;
    }

    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async function getTrafficVolumneByCategory(type, key, selectedDate, selectedtime) {
    let url = `${apiUrl}/traffic/avg/category?`;
    if (type) {
      url += `&type=${type}`;
    }
    if (key) {
      url += `&key=${key}`;
    }
    if (selectedDate) {
      const [day, month, year] = selectedDate.split("-");
      const formattedDate = `${year}-${month}-${day}`;
      url += `&date=${formattedDate}`;
    }
    if (selectedtime) {
      url += `&time=${selectedtime}`;
    }

    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getTrafficVolumneByCategoryAll(type, value) {
    let url = `${apiUrl}/traffic/all?`;
    if (type) {
      url += `&type=${type}`;
    }
    if (value) {
      url += `&value=${value}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getTrafficDataForTable(type, value) {
    let url = `${apiUrl}/traffic/tableData?`;
    if (type) {
      url += `&type=${type}`;
    }
    if (value) {
      url += `&value=${value}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getTrafficDataByDay() {
    let url = `${apiUrl}/traffic/day?`;
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getData() {
    let url = `${apiUrl}/advisory/jsonData`;
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getheatmapTableData(xaxis, yaxis, xvalue, yvalue, buildingId) {
    let url = `${apiUrl}/bms/alarms/timeseries/heatmap/tableData?xaxis=${xaxis}&yaxis=${yaxis}&xvalue=${xvalue}&yvalue=${yvalue}`;
    if (buildingId) {
      url += `&buildingID=${buildingId}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }

  async function getDeviceState() {
    return axios
      .get(`${simulationUrl}/device_state`)
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error fetching data from API", error);
        throw error;
      });
  }

  async function setDeviceState(data) {
    return axios
      .post(`${simulationUrl}/device_state`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function setWorkStatusData(data) {
    return axios
      .post(`${simulationUrl}/wt_status`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function setWorkStatusGenData(data) {
    return axios
      .post(`${simulationUrl}/wt_status_gen`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function verifyIncident(sopId) {
    return axios
      .get(`${apiUrl}/sop/notify?id=${sopId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async function createSop(summary, details, masterCriticalityId, masterCategoryTypeId) {
    let url = `${apiUrl}/sop/create?`;
    if (summary) {
      url += `&Summary=${summary}`;
    }
    if (details) {
      url += `&Details=${details}`;
    }
    if (masterCriticalityId) {
      url += `&MasterCriticalityId=${masterCriticalityId}`;
    }
    if (masterCategoryTypeId) {
      url += `&MasterCategoryTypeId=${masterCategoryTypeId}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  async function getAiPromt(input) {
    return axios
      .get(`${apiUrl}/converse?input=${input}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error :", error);
        return error;
      });
  }

  async function updateSOPStatus(sopId, status) {
    return axios
      .get(`${apiUrl}/sop/status?id=${sopId}&status=${status}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  async function getliviabilityindex(source) {
    let url = `${apiUrl}/livability?`;
    if (source) {
      url += `&source=${source}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const getLocation = async (latitude, longitude) => {
    return axios
      .get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.error("Error :", error));
  };

  async function updateLivabilityIndex(payload) {
    return axios
      .post(`${apiUrl}/livability/update`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async function updateTrafficDates() {
    return axios
      .post(`${apiUrl}/traffic/update_dates`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getSubsystemCwpAdvisories() {
    return axios
      .get(`${apiUrl}/dashboard/advisories`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return (
    <HttpServiceContext.Provider
      value={{
        sopGeneric,
        sop,
        getBmsAlarmsDataCount,
        getFrequencyOfAssetsAll,
        getBmsAlarmsColumnDataCount,
        getFrequencyOfyearmonth,
        getTimeseriesOfColumnsValue,
        getTimeseriesColumnData,
        gettrafficheatmap,
        getTrafficDataByHour,
        getTrafficVolumneByCategory,
        getTrafficVolumneByCategoryAll,
        getTrafficDataForTable,
        getTrafficDataByDay,
        getData,
        getheatmapTableData,
        getDeviceState,
        setDeviceState,
        verifyIncident,
        getRandomNumber,
        getAiPromt,
        getLocation,
        updateSOPStatus,
        setWorkStatusData,
        setWorkStatusGenData,
        setSimulationUrl,
        simulationUrl,
        createSop,
        getliviabilityindex,
        updateLivabilityIndex,
        updateTrafficDates,
        getSubsystemCwpAdvisories,
        trafficIframeURL,
        visioWebURL,
      }}
    >
      {children}
    </HttpServiceContext.Provider>
  );
};
