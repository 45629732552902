/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { HttpServiceContext } from "./httpContext";
export const CommonServiceContext = React.createContext();

const trafficdeckglIframeURL = "/deckgl-traffic/deck.html";
const traffic3DIframeURL = "/trips";
const deltaURL = "/Delta";
const modelIssueURL = "/model?anomalies=ahu";
const modelURL = "/model";
const analyticsURL = "/analytics";
const gmapURL = "/3dmaps";

export const CommonServiceProvider = ({ children }) => {
  const httpContext = useContext(HttpServiceContext);

  const [selectedtype, setSelectedType] = useState([]);
  const [disableDeploy, setDisableDeploy] = useState(false);
  const [startPolice, setStartPolice] = useState(false);
  const [startAmbulance, setStartAmbulance] = useState(false);
  const [startFire, setStartFire] = useState(false);
  const [sopStatus, setSOPStatus] = useState({
    fileReport: true,
    police: true,
    ambulance: true,
    fire: true,
  });
  const [inputFieldValue, setInputFieldValue] = useState("");
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [bId, setBId] = useState();
  const [chartData, setChartData] = useState();
  const [ahuIncident, setAhuIncident] = useState(false);
  const [showAssetAnalytics, setShowAssetAnalytics] = useState(false);
  const [selectedData, setSelectedData] = useState({
    assetid: null,
    severity: null,
    classname: null,
    value: null,
    buildingId: null,
  });
  const [changedNodes, setChangedNodes] = useState([]);
  const [device_state, setDevice_State] = useState({});
  const [olddevice_state, setOldDevice_State] = useState({});

  useEffect(() => {
    if (
      (selectedtype.length === 1 && !sopStatus.police) ||
      (selectedtype.length === 2 && !sopStatus.police && !sopStatus.ambulance) ||
      (selectedtype.length === 3 && !sopStatus.police && !sopStatus.ambulance && !sopStatus.fire)
    ) {
      updateDeviceState();
    }
  }, [sopStatus]);

  async function updateDeviceState() {
    let data = await httpContext.getDeviceState();
    if (data) {
      data["SIM-004"].state = false;
      httpContext.setDeviceState(data);
    }
  }

  function handleChartClick(params, data, filter) {
    setSelectedData({
      assetid: filter ? filter[0] : null,
      severity: filter ? filter[1] : null,
      classname: filter ? filter[3] : null,
      value: params,
    });
    if (data) {
      setChartData(data);
    } else {
      setChartData();
    }
    setOpenModel(true);
  }

  const colorStops = {
    yellow: [
      { offset: 0, color: "hsla(51, 90%, 61%)" },
      { offset: 1, color: "hsla(51, 90%, 61%)" },
    ],
    redOrange: [
      { offset: 0, color: "hsla(0, 69%, 59%, 1)" },
      { offset: 1, color: "hsla(32, 62%, 60%, 1)" },
    ],
    lightBlueViolet: [
      { offset: 0, color: "hsla(194, 80%, 61%, 1)" },
      { offset: 1, color: "hsla(266, 68%, 65%, 1)" },
    ],
    greenShade: [
      { offset: 0, color: "hsla(114, 64%, 61%, 1)" },
      { offset: 1, color: "hsla(162, 54%, 46%, 1)" },
    ],
    pinkPurple: [
      { offset: 0, color: "hsla(306, 66%, 65%, 1)" },
      { offset: 1, color: "hsla(311, 87%, 35%, 1)" },
    ],
    yellowShade: [
      { offset: 0, color: "#DACE81" },
      { offset: 1, color: "#C9A445" },
    ],
    blueShade: [
      { offset: 0, color: "#7EE2BE" },
      { offset: 1, color: "#58A5DC" },
    ],
    blueViolet: [
      { offset: 0, color: "hsla(241, 68%, 61%, 1)" },
      { offset: 1, color: "hsla(274, 68%, 61%, 1)" },
    ],
    cyanBlue: [
      { offset: 0, color: "hsla(158, 63%, 69%, 1)" },
      { offset: 1, color: "hsla(205, 65%, 60%, 1)" },
    ],
    yellowGreen: [
      { offset: 0, color: "hsla(75, 61%, 64%, 1)" },
      { offset: 1, color: "hsla(119, 47%, 50%, 1)" },
    ],
    redShade: [
      { offset: 0, color: "hsla(345, 74%, 63%, 1)" },
      { offset: 1, color: "hsla(15, 73%, 64%, 1)" },
    ],
    redYellowGreen: [
      { offset: 0, color: "hsla(11, 71%, 62%, 1)" },
      { offset: 0.5, color: "hsla(59, 678%, 71%, 1)" },
      { offset: 1, color: "hsla(112, 64%, 61%, 1)" },
    ],
    darkRed: [
      { offset: 0, color: "hsla(0,   86%, 65%, 1)" },
      { offset: 1, color: "hsla(0, 60%, 49%, 1)" },
    ],
  };

  const ahutemperaturedata = (x, y) => {
    var ahutemp;
    var tempval;
    setInterval(() => {
      ahutemp = getRandomNum(x, y);
      tempval = ahutemp + "°C";
      setInputFieldValue(tempval);
    }, 5000);
  };

  const getRandomNum = (min, max) => {
    return (Math.floor(Math.random() * (max - min + 1)) + min) / 100;
  };
  return (
    <CommonServiceContext.Provider
      value={{
        openModel,
        ahutemperaturedata,
        deltaURL,
        traffic3DIframeURL,
        trafficdeckglIframeURL,
        analyticsURL,
        modelIssueURL,
        modelURL,
        setOpenModel,
        bId,
        setBId,
        setSelectedData,
        selectedData,
        handleChartClick,
        chartData,
        setAhuIncident,
        ahuIncident,
        showAssetAnalytics,
        setShowAssetAnalytics,
        colorStops,
        showAnalytics,
        gmapURL,
        inputFieldValue,
        setShowAnalytics,
        setSOPStatus,
        sopStatus,
        startPolice,
        setStartPolice,
        startAmbulance,
        setStartAmbulance,
        startFire,
        setStartFire,
        setDisableDeploy,
        disableDeploy,
        setSelectedType,
        selectedtype,
        changedNodes,
        setChangedNodes,
        device_state,
        setDevice_State,
        olddevice_state,
        setOldDevice_State,
      }}
    >
      {children}
    </CommonServiceContext.Provider>
  );
};
