/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useContext, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { useGLTF } from "@react-three/drei";
import ClipLoader from "react-spinners/ClipLoader";
import { MeshStandardMaterial } from "three"; // Assuming you're using Three.js
import { ModelServiceContext } from "../../config/modelContext";
import { useLocation } from "react-router-dom";
import { CommonServiceContext } from "../../config/commonContext";

const subAssets = [
  {
    assetName: "Digital Citis Room",
    id: "data_sit_02_41",
    x: 65.19,
    y: 14,
    z: -8.5,
  },
  {
    assetName: "Data Center",
    issuekey: "datacenter",
    id: "data_sit_02_32",
    x: 85,
    y: 14,
    z: -8.5,
  },
  {
    assetName: "Cooling System",
    issuekey: "ahu",
    id: "data_sit_02_31",
    x: 80,
    y: 40,
    z: -8.5,
  },
  {
    assetName: "FCU",
    issuekey: "fcu",
    id: "data_sit_02_131",
    x: 60,
    y: 51,
    z: -2.5,
  },
];
const Model = () => {
  const { scene } = useGLTF("/dell_new.glb");
  const modelContext = useContext(ModelServiceContext);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const commonContext = useContext(CommonServiceContext);

  var cupMaterial = new MeshStandardMaterial();

  const viewIssue = () => {
    commonContext.setShowAssetAnalytics(true);
    // let viewdata = { message: "Show AHU" };
    // window.parent.postMessage(viewdata, "*");
  };

  window.addEventListener("message", (event) => {
    if (event.data) {
      modelContext.setAhuIncident(event.data.state);
    }
  });

  useEffect(() => {
    modelContext.setAhuIncident(commonContext.ahuIncident);
    if (commonContext.ahuIncident) {
      subAssets.forEach((asset) => {
        if (asset.issuekey === "ahu") {
          setTimeout(() => {
            modelContext.navigate(asset.x, asset.y, asset.z, asset.id);
          }, 500);
        }
      });
    }
  }, [commonContext.ahuIncident]);

  useEffect(() => {
    modelContext.setObject(scene);
  }, [scene.children.length]);

  useEffect(() => {
    const anomalies = params.get("anomalies");
    if (anomalies) {
      subAssets.forEach((asset) => {
        if (asset.issuekey === anomalies) {
          setTimeout(() => {
            modelContext.navigate(asset.x, asset.y, asset.z, asset.id);
            // meshClick(asset.id);
          }, 500);
        }
      });
    }
  }, [modelContext.controls]);

  const meshClick = (event) => {
    // assetData.forEach((ele) => {
    //   if (ele.meshId === event.object.name) {
    //     modelContext.navigate(ele.x, ele.y, ele.z, event.object.name, "detailView");
    //   }
    // });

    subAssets.forEach((asset) => {
      // if (typeof event == "string") {
      // if (asset.id === event) {
      //   modelContext.openMeshDetails(event, asset.x, asset.y, asset.z);
      // }
      // } else {
      if (asset.id === event.object.name) {
        modelContext.openMeshDetails(event.object.name, asset.x, asset.y, asset.z);
      }
      // }
    });
  };

  const cupLoaded = (event) => {
    const object = event;
    modelContext.setObject(event);
    cupMaterial = new MeshStandardMaterial(object.getObjectByName("Mesh10018").material);
    if (cupMaterial) {
      cupMaterial.color.setHex(parseInt("#FFFFFF".substring(1), 16));
    }
  };

  return (
    <div className=" p-2 position-relative h-100">
      <Canvas
        camera={{ position: [60, 55.9, 68.85] }}
        style={{
          background: "#09477a",
          width: "100%",
          height: "100%",
        }}
      >
        <ambientLight intensity={0.8} />
        <pointLight intensity={3.5} position={[1, 1, 0]} />
        <pointLight intensity={3.5} position={[-1, 1, 0]} />
        <pointLight intensity={0.5} position={[1, -1, 0]} />
        <OrbitControls ref={modelContext.controls} target={[60, 25, -43.5]} />
        <directionalLight intensity={2.5} />
        <Suspense fallback={<ClipLoader className="modelLoader" color="white" size={20} aria-label="Loading Spinner" data-testid="loader" />}>
          {scene.children.length && <primitive object={scene} onClick={(event) => meshClick(event)} />}
        </Suspense>
      </Canvas>
      {commonContext.ahuIncident && (
        <div className="position-absolute anomaly-card">
          <div className="text-center fw-bold fs-6">Anomalies</div>
          <div className="m-2 fs-7 d-flex justify-content-between">
            <div>AHU - Return air temperature is predicted to be higher due to a persistent anomaly. : 1-T2-T07-13-01.01-AC-ACON-AHU-0001</div>
            <div className="btn-padding w-10 my-1">
              <button className="btn bttn cursor-pointer" onClick={viewIssue}>
                View
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

useGLTF.preload("./dell_new.glb");
export default Model;
