/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsSolidGauge from "highcharts/modules/solid-gauge";
import Variablepie from "highcharts/modules/variable-pie";
import { useState } from "react";
import { HttpServiceContext } from "../../config/httpContext";
import regression from "regression";
import { CommonServiceContext } from "../../config/commonContext";
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
Variablepie(Highcharts);

function LineHighChart(props) {
  const { data, filter, click } = props;
  const [chartData, setChartData] = useState();
  const [seriesData, setSeriesData] = useState();
  const [seriesData1, setSeriesData1] = useState();
  const [seriesData2, setSeriesData2] = useState();
  const [seriesData3, setSeriesData3] = useState();
  const [seriesData4, setSeriesData4] = useState();
  const [seriesData5, setSeriesData5] = useState();
  const [seriesData6, setSeriesData6] = useState();
  const [seriesData7, setSeriesData7] = useState();
  const [seriesData8, setSeriesData8] = useState();
  const [seriesData9, setSeriesData9] = useState();
  const [xaxisLabels, setXaxisLabels] = useState([]);

  const httpContext = useContext(HttpServiceContext);
  const commonContext = useContext(CommonServiceContext);

  var regdata;

  function calculateRegressionLine(rdata) {
    var result = regression.linear(rdata);
    if (data.apicategory === "actual") {
      let trimmedData = result.points.slice(4).concat(result.points.slice(0, 4));
      if (data.regRev) trimmedData.reverse();
      return trimmedData;
    } else return result.points;
  }

  async function getBmsAlarmsData() {
    let dt = await httpContext.getBmsAlarmsDataCount(data.type, filter[0], filter[1], filter[2], null, data.order, data.limit ?? data.limit);
    if (dt) {
      setChartData(dt.data);
    }
  }

  async function getBmsAlarmsDataCount() {
    let dt = await httpContext.getBmsAlarmsColumnDataCount(data.xaxis, data.yaxis, filter[0], filter[1], filter[2]);
    if (dt) {
      let transformedData = dt.data.map(([x, y, z]) => [x, z, y]);
      setChartData(transformedData);
    }
  }

  async function getTimeseriesOfColumnData(values) {
    values.forEach(async (ele) => {
      let dt = await httpContext.getTimeseriesOfColumnsValue(data.type, ele, filter[0], filter[1], filter[2]);
      switch (ele) {
        case "Summer":
          if (dt) {
            setSeriesData1(dt.data);
            setSeriesData(dt.data);
          }
          break;
        case "Winter":
          if (dt) {
            setSeriesData2(dt.data);
            setSeriesData(dt.data);
          }
          break;
        case "1 - High":
          if (dt) {
            setSeriesData3(dt.data);
            setSeriesData(dt.data);
          }
          break;
        case "2 - Medium":
          if (dt) {
            setSeriesData4(dt.data);
            setSeriesData(dt.data);
          }
          break;
        case "3 - Low":
          if (dt) {
            setSeriesData5(dt.data);
            setSeriesData(dt.data);
          }
          break;
        case "BACnet":
          if (dt) {
            setSeriesData6(dt.data);
            setSeriesData(dt.data);
          }
          break;
        case "CPMS":
          if (dt) {
            setSeriesData7(dt.data);
            setSeriesData(dt.data);
          }
          break;
        case "Central Battery System-Medium":
          if (dt) {
            setSeriesData8(dt.data);
            setSeriesData(dt.data);
          }
          break;
        case "Meter":
          if (dt) {
            setSeriesData9(dt.data);
            setSeriesData(dt.data);
          }
          break;
        default:
      }
      if (dt.data && dt.data.length > xaxisLabels.length) {
        let label = dt.data.map((d) => d[0]);
        setXaxisLabels(label);
      }
    });
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (data.apicategory === "timeseries") {
        if (data.values) {
          getTimeseriesOfColumnData(data.values);
        } else {
          getBmsAlarmsData();
        }
      } else if (data.apicategory === "frequency") {
        getBmsAlarmsDataCount();
      } else if (data.apicategory === "actual") {
        setChartData(data.chartData);
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [filter[0], filter[1], filter[2]]);

  useEffect(() => {
    if (
      chartData ||
      seriesData ||
      seriesData1 ||
      seriesData2 ||
      seriesData3 ||
      seriesData4 ||
      seriesData5 ||
      seriesData6 ||
      seriesData7 ||
      seriesData8 ||
      seriesData9
    ) {
      if (data.chartData) regdata = Object.entries(data?.chartData)?.map((d) => [d[0], d[1]]);

      const chart = Highcharts.chart(data.id, {
        chart: {
          type: "line",
          marginTop: 50,
          marginRight: 35,
          backgroundColor: "hsla(202, 87%, 53%, 0.16)",
        },

        accessibility: {
          enabled: false,
        },

        time: {
          useUTC: false,
        },

        title: {
          text: data.title,
          style: {
            fontSize: "12px",
            color: "#fff",
          },
          floating: true,
          align: "left",
          x: 50,
          y: 10,
        },
        legend: {
          layout: "horizontal",
          align: "center",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#FFFFFF",
          },
          itemWidth: 150,
          y: 15,
          x: -15,
          symbolHeight: 5,
          navigation: {
            arrowSize: 5,
          },
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: data.values ? Object.entries(xaxisLabels)?.map((item) => item[1]) : chartData?.map((item) => item[0]),
          labels: {
            style: {
              color: "#FFFFFF",
              fontSize: "10px",
            },
          },
          title: {
            text: data.xaxis ?? data.column,
            style: {
              fontSize: "12px",
              color: "#fff",
            },
            x: -15,
            y: 0,
          },
        },
        yAxis: {
          min: data.min ?? null,
          max: data.max ?? null,
          title: {
            text: data.yaxis ?? "Count",
            x: 0,
            y: 5,
            style: {
              color: "#FFFFFF",
              fontSize: "12px",
            },
          },
          labels: {
            x: -10,
            y: 5,
            style: { color: "#FFFFFF", fontSize: 10 },
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: "#808080",
            },
          ],
        },

        series: !data.values
          ? data.regression === true
            ? [
                {
                  name: data.seriesName,
                  data: chartData?.map((item) => ({
                    y: item[1],
                  })),
                  color: "#00ce00",
                  point: {
                    events: {
                      click: function (params) {
                        if (data.apicategory !== "actual") commonContext.handleChartClick(params.point.category, data, filter);
                      },
                    },
                  },
                },
                {
                  name: "Regression Line",
                  data: calculateRegressionLine(data.apicategory === "actual" ? data.regData : chartData).map((item) => ({
                    y: item[1],
                  })),
                  color: "hsla(0, 69%, 59%, 1)",
                  dashStyle: "Dash",
                  marker: {
                    enabled: false,
                  },
                  point: {
                    events: {
                      click: function (params) {
                        if (data.apicategory !== "actual") commonContext.handleChartClick(params.point.category, data, filter);
                      },
                    },
                  },
                },
              ]
            : [
                {
                  name: data.seriesName,
                  data:
                    data.apicategory === "actual"
                      ? chartData
                      : chartData?.map((item) => ({
                          y: item[1],
                        })),
                  color: "#00ce00",
                  point: {
                    events: {
                      click: function (params) {
                        if (data.apicategory !== "actual") commonContext.handleChartClick(params.point.category, data, filter);
                      },
                    },
                  },
                },
              ]
          : null,
      });
      if (data.values) {
        data.values.map((v) => {
          switch (v) {
            case "Summer":
              chart.addSeries({
                name: "Summer",
                data: seriesData1?.map((item) => ({
                  y: item[2],
                })),
                color: "#fcf000",
                point: {
                  events: {
                    click: function (params) {
                      commonContext.handleChartClick(params.point.category, data, filter);
                    },
                  },
                },
              });
              break;
            case "Winter":
              chart.addSeries({
                name: "Winter",
                data: seriesData2?.map((item) => ({
                  y: item[2],
                })),
                color: "#00ff00",
                point: {
                  events: {
                    click: function (params) {
                      commonContext.handleChartClick(params.point.category, data, filter);
                    },
                  },
                },
              });
              break;
            case "1 - High":
              chart.addSeries({
                name: "1 - High",
                data: seriesData3?.map((item) => ({
                  y: item[2],
                })),
                color: "hsla(0, 69%, 49%, 1)",
                point: {
                  events: {
                    click: function (params) {
                      commonContext.handleChartClick(params.point.category, data, filter);
                    },
                  },
                },
              });
              break;
            case "2 - Medium":
              chart.addSeries({
                name: "2 - Medium",
                data: seriesData4?.map((item) => ({
                  y: item[2],
                })),
                color: "hsla(15, 73%, 54%, 1)",
                point: {
                  events: {
                    click: function (params) {
                      commonContext.handleChartClick(params.point.category, data, filter);
                    },
                  },
                },
              });
              break;
            case "3 - Low":
              chart.addSeries({
                name: "3 - Low",
                data: seriesData5?.map((item) => ({
                  y: item[2],
                })),
                color: "hsla(122, 74%, 46%, 1)",
                point: {
                  events: {
                    click: function (params) {
                      commonContext.handleChartClick(params.point.category, data, filter);
                    },
                  },
                },
              });
              break;
            case "BACnet":
              chart.addSeries({
                name: "BACnet",
                data: seriesData6?.map((item) => ({
                  y: item[2],
                })),
                color: "hsla(345, 74%, 53%, 1)",
                point: {
                  events: {
                    click: function (params) {
                      commonContext.handleChartClick(params.point.category, data, filter);
                    },
                  },
                },
              });
              break;
            case "CPMS":
              chart.addSeries({
                name: "CPMS",
                data: seriesData7?.map((item) => ({
                  y: item[2],
                })),
                color: "hsla(75, 61%, 64%, 1)",
                point: {
                  events: {
                    click: function (params) {
                      commonContext.handleChartClick(params.point.category, data, filter);
                    },
                  },
                },
              });
              break;
            case "Central Battery System-Medium":
              chart.addSeries({
                name: "Central Battery System-Medium",
                data: seriesData8?.map((item) => ({
                  y: item[2],
                })),
                color: "#fcf000",
                point: {
                  events: {
                    click: function (params) {
                      commonContext.handleChartClick(params.point.category, data, filter);
                    },
                  },
                },
              });
              break;
            case "Meter":
              chart.addSeries({
                name: "Meter",
                data: seriesData9?.map((item) => ({
                  y: item[2],
                })),
                color: "hsla(274, 68%, 61%, 1)",
                point: {
                  events: {
                    click: function (params) {
                      if (data.apicategory !== "actual") commonContext.handleChartClick(params.point.category, data, filter);
                    },
                  },
                },
              });
              break;
            default:
          }
        });
      }
      if (data.regression && seriesData) {
        const rgdata = seriesData.map((d) => [d[0], d[2]]);
        chart.addSeries({
          name: "Regression Line",
          data: data.regression
            ? calculateRegressionLine(rgdata).map((item) => ({
                y: item[1],
              }))
            : null,
          color: "hsla(0, 69%, 59%, 1)",
          dashStyle: "Dash",
          marker: {
            enabled: false,
          },
          point: {
            events: {
              click: function (params) {
                commonContext.handleChartClick(params.point.category, data, filter);
              },
            },
          },
        });
      }
      if (data.advId) {
        chart.renderer
          .button(
            data.button,
            chart.plotWidth + chart.plotLeft - 100, // X position
            chart.plotTop - 40, // Y position
            function () {
              click(data.advId);
            },
            {
              fill: "#337ab7",
              style: {
                color: "white",
                fontWeight: "bold",
              },
              states: {
                hover: {
                  fill: "#135296",
                },
              },
            }
          )
          .add();
      }

      return () => {
        chart.destroy();
      };
    }
  }, [
    data.id,
    chartData,
    seriesData1,
    seriesData2,
    seriesData3,
    seriesData4,
    seriesData5,
    seriesData6,
    seriesData7,
    seriesData8,
    seriesData9,
    xaxisLabels,
  ]);

  return <div id={data.id} style={{ width: "46vw", height: "60vh" }}></div>;
}

export default LineHighChart;
