/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from "react";
import ReactECharts from "echarts-for-react";
import subsystems from "../../assets/subsystem.json";
import { CommonServiceContext } from "../../config/commonContext";

function SankeyChart(props) {
  const { data, chartData, onNodeClick, setTab, anamoly, actualvalue, advisory, nodes } = props;
  const [option, setOption] = useState({});
  const [selectedNode, setSelectedNode] = useState("Liveability Index");
  const [showCard, setShowCard] = useState(false);
  const commonContext = useContext(CommonServiceContext);
  const subsystemData = subsystems.livability_index.charts.leftpanel[0];
  // const [memoizedData, setMemorizedData] = useState();
  // const memoizedData = useMemo(() => data, [data]);
  // const stableAnamoly = useMemo(() => anamoly, [anamoly]);
  // const memoizedData = useMemo(() => cloneDe(data), []);

  // useEffect(() => {
  //   console.log("pooling data");
  // }, [data]);

  // useEffect(() => {
  //   console.log("pooling chartData");
  // }, [chartData]);

  // useEffect(() => {
  //   console.log("pooling anamoly");
  // }, [chartData]);

  // useEffect(() => {
  //   console.log("pooling advisory");
  // }, [chartData]);

  const toggleCard = () => {
    setShowCard(!showCard);
  };

  const handleChartClick = (event) => {
    if (event.dataType !== "edge") {
      if (event.data?.key) {
        onNodeClick(event);
        if (event.name !== selectedNode) setSelectedNode(event.name);
        else setSelectedNode("Liveability Index");
      } else {
        console.log("not found");
        setTab();
      }
    }
  };
  useEffect(() => {
    const targets = chartData?.filter((link) => link.source === selectedNode).map((link) => link.target);
    let filteredNodes = data?.nodes.filter((node) => node.name === selectedNode || targets?.includes(node.name));

    const newOption = {
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          let value = params.value !== undefined ? params.value : actualvalue;
          if (params.dataType === "node") {
            if (params.name === selectedNode) {
              if (params.name === "Liveability Index") {
                return `${params.name}: ${actualvalue.toFixed(2)}/100<br />Remaining: ${(100 - actualvalue).toFixed(2)}/100`;
              } else {
                let remainingValue = 100 - (value / targets?.length).toFixed(2);
                return `${params.name}: ${(value / targets?.length).toFixed(2)}/100<br />Remaining: ${remainingValue.toFixed(2)}/100`;
              }
            } else {
              let remainingValue = 100 - value;
              return `${params.name}: ${value.toFixed(2)}/100<br />Remaining: ${remainingValue.toFixed(2)}/100`;
            }
          }
        },
      },

      allowDrag: false,
      animation: false,
      series: {
        type: "sankey",
        layout: "vertical",
        height: "80%",
        bottom: "20%",
        left: "2%",
        emphasis: {
          focus: "adjacency",
        },
        label: {
          color: "#fff",
        },
        draggable: false,
        layoutIterations: 0,
        nodeGap: 5,
        data: filteredNodes ?? data?.nodes,
        links: chartData || [],
        lineStyle: {
          color: "target",
          curveness: 0.5,
          opacity: 0.4,
        },
      },
    };
    setOption(newOption);
    data?.nodes?.forEach((details, index) => {
      let isRed = false;

      for (const adv of advisory || []) {
        if ((details.name === adv.source || details.name === adv.pillar) && commonContext.device_state[adv.SimId]?.state) {
          isRed = true;
          break;
        }
      }

      details.itemStyle = isRed
        ? {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0.15, color: "rgba(255, 255, 255, 0.1)" },
                { offset: 0.15, color: "red" },
              ],
            },
          }
        : subsystemData.nodes[index].itemStyle;
    });
  }, [data, anamoly, actualvalue]);

  return (
    <div className="chart-container mt-4">
      <div>
        <div className="px-4 pt-2">
          <img src="/certificate.png" className="certificate" alt="" onClick={toggleCard}></img>
        </div>
        {showCard && (
          <div className="certificate-card position-absolute">
            <div className="card-body">
              <div className="d-flex justify-content-between p-2">
                <h6 className="card-title p-1">Liveability Index Framework Based On:</h6>
                <button type="button" className="close btn bttn" onClick={() => setShowCard(!showCard)} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="d-flex p-2 mb-3">
                <img src="/c3.png" className="cert-img" alt=""></img>
                <img src="/c5.png" className="certif-img" alt=""></img>
                <img src="/c4.png" className="certif-img" alt=""></img>
                <img src="/c1.png" className="cert-img" alt=""></img>
                <img src="/c2.png" className="cert-img" alt=""></img>
              </div>
            </div>
          </div>
        )}
      </div>
      <ReactECharts
        className="h-54vh"
        key={JSON.stringify(option)}
        onEvents={{
          click: handleChartClick,
        }}
        option={option}
      />
    </div>
  );
}

export default SankeyChart;
