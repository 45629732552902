/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { CommonServiceContext } from "../../config/commonContext";
function LineCharts({ data, buildingId, style }) {
  const [option, setOption] = useState({});
  const commonContext = useContext(CommonServiceContext);

  useEffect(() => {
    let minVal = Infinity;
    let maxVal = -Infinity;
    if (data.key === "LI") {
      data?.data?.forEach((index) => {
        const values = index.value;
        const indexMin = Math.min(...values);
        const indexMax = Math.max(...values);
        if (indexMin < minVal) minVal = indexMin;
        if (indexMax > maxVal) maxVal = indexMax;
      });
      if (minVal > 5) {
        minVal = minVal - 1;
      }
    }
    minVal = Math.floor(minVal);
    maxVal = Math.ceil(maxVal);

    const getOption = () => ({
      color:
        data?.title === "Forecast - Pillars"
          ? ["#ff8b3d", "#98fb98", "#c7ea46", "#f4bc1c", "#007BFF", "#FFC0CB", "#00FF00"]
          : [
              {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: commonContext.colorStops.cyanBlue,
              },
              {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: commonContext.colorStops.redShade,
              },
              {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: commonContext.colorStops.redOrange,
              },
              {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: commonContext.colorStops.yellowShade,
              },
              {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: commonContext.colorStops.blueShade,
              },
            ],

      grid: {
        top: "15%",
        left: "10%",
        bottom: "10%",
      },
      title: {
        text: data?.title ?? "",
        textStyle: {
          color: "#ffffff",
          fontSize: 12,
        },
        textAlign: "left",
        left: "3%",
        top: "1%",
      },
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: data?.xData ?? [],
        name: data?.xaxis,
        nameLocation: "center",
        nameGap: 30,
        nameTextStyle: {
          color: "white",
          fontSize: 10,
        },
        axisLabel: {
          color: "white",
          fontSize: 10,
        },
      },
      yAxis: {
        type: "value",
        min: data?.min ?? data.key === "LI" ? minVal : 0,
        max: data?.max ?? data.key === "LI" ? maxVal : data?.max,
        interval: data?.interval,
        name: data?.yaxis,
        nameLocation: "center",
        nameGap: 40,
        nameTextStyle: {
          color: "white",
          fontSize: 10,
        },
        axisLabel: {
          color: "white",
          fontSize: 10,
        },
        splitLine: {
          show: false,
        },
      },
      series: data?.data?.map((item) => {
        let selectedValue;

        switch (buildingId) {
          case "T07":
            selectedValue = buildingId ? item.value1 : item.value;
            break;
          case "SSIF" && "FGL":
            selectedValue = buildingId ? item.value2 : item.value;
            break;
          case "R01" && "G03" && "M":
            selectedValue = buildingId ? item.value3 : item.value;
            break;
          case "R02" && "I02":
            selectedValue = buildingId ? item.value4 : item.value;
            break;
          case "SSIF":
            selectedValue = buildingId ? item.value3 : item.value;
            break;
          default:
            selectedValue = item.value;
        }

        return {
          name: item.name,
          type: "line",
          data: buildingId ? selectedValue : item?.value,
          step: item.step,
          itemStyle: {
            color: item.color,
          },
        };
      }),
    });

    setOption(getOption());
  }, [data, buildingId]);

  return (
    <div className="chart-container mt-2">
      <ReactECharts key={JSON.stringify(option)} option={option} style={style} />
    </div>
  );
}

export default LineCharts;
