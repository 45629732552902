/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { CommonServiceContext } from "../../config/commonContext";
import { HttpServiceContext } from "../../config/httpContext";

function LineChart(props) {
  const { buildingId, data, selectedDate, selectedtime, style } = props;
  const [chartData, setChartData] = useState([]);
  const [option, setOption] = useState({});
  const [xlabel, setXlabel] = useState([]);
  const [linchart1, setlinechart1] = useState();
  const [linchart2, setlinechart2] = useState();
  const [linchart3, setlinechart3] = useState();

  const httpContext = useContext(HttpServiceContext);
  const commonContext = useContext(CommonServiceContext);
  async function getChartData() {
    let dt = await httpContext.getData();
    if (dt) {
      setlinechart1(dt.data.chart.trafficTrend.data1);
      setlinechart2(dt.data.chart.trafficTrend.data2);
      setlinechart3(dt.data.chart.trafficTrend.data3);
      setXlabel(dt.data.chart.trafficTrend.xData);
    }
  }
  async function getBmsAlarmsData() {
    let dt = await httpContext.getBmsAlarmsDataCount(data.type, null, null, null, buildingId, data.order, data.limit ?? data.limit);
    if (dt) {
      setChartData(dt.data);
    }
    if (dt.data && dt.data.length > xlabel.length) {
      let label = dt.data.map((d) => d[0]);
      setXlabel(label);
    }
  }

  async function getTrafficData() {
    let dt = await httpContext.getTrafficDataByHour(selectedDate, selectedtime);
    if (dt) {
      setChartData(dt.data);
      if (dt.data && dt.data.length) {
        let label = dt.data.map((d) => d[0]);
        setXlabel(label);
      }
    }
  }
  useEffect(() => {
    if (data.apicategory === "timeseries") {
      getBmsAlarmsData();
    }
    if (data?.key === "traffic") {
      if (data.type === "time_only") {
        getTrafficData();
      } else {
        if (data.datatype === "actual") {
          getChartData();
        } else {
          setChartData(data.data);
        }
      }
    }
    if (data.key === "delta") {
      setChartData(data.data);
    }
  }, [buildingId, selectedDate, selectedtime]);

  useEffect(() => {
    const newOption = {
      title: {
        text: data?.title,
        textStyle: {
          color: "#ffffff",
          fontSize: 10,
        },
        textAlign: "left",
        left: "3%",
        top: "1%",
      },
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        data: data.xData ?? xlabel,
        axisLabel: {
          color: "white",
          fontSize: 8,
        },
      },
      yAxis: {
        min: data.min ?? 0,
        type: "value",
        axisLabel: {
          color: "white",
          fontSize: 8,
        },
        splitLine: {
          show: false,
        },
      },
      grid: {
        top: 40,
        left: 40,
        right: 15,
      },
      series:
        data.datatype === "actual"
          ? [
              {
                name: "Actual",
                type: "line",
                itemStyle: {
                  color: commonContext.colorStops.lightBlueViolet[0].color,
                },
                data: linchart1?.map((d) => d[1]),
              },
              {
                name: "Predicted",
                type: "line",
                itemStyle: {
                  color: commonContext.colorStops.redOrange[0].color,
                },
                data: linchart2?.map((d) => d[1]),
              },
              {
                name: "forecast_3_days",
                type: "line",
                lineStyle: {
                  color: commonContext.colorStops.greenShade[0].color,
                  width: 2,
                  type: "dashed",
                },
                data: linchart3?.map((d) => d[1]),
              },
            ]
          : data.key === "delta"
          ? [
              {
                name: data.column,
                type: "line",
                itemStyle: {
                  color: commonContext.colorStops.lightBlueViolet[0].color,
                },
                markPoint: chartData.map((e) =>
                  e < 22 ? commonContext.colorStops.lightBlueViolet[0].color : commonContext.colorStops.redOrange[0].color
                ),
                markLine: {
                  data: [
                    {
                      yAxis: data.Low ?? 0,
                      name: "Max Line 16",
                      label: {
                        formatter: "16",
                      },
                      lineStyle: {
                        type: "dashed",
                        color: "green",
                        width: 2,
                      },
                    },
                    {
                      yAxis: data.High ?? 0,
                      name: "Max Line 22",
                      label: {
                        formatter: "22",
                      },
                      lineStyle: {
                        type: "dashed",
                        color: "green",
                        width: 2,
                      },
                    },
                  ],
                },
                data: chartData,
              },
            ]
          : [
              {
                name: data.key === "traffic" ? "traffic" : "Alarm",
                type: "line",
                itemStyle: {
                  color: commonContext.colorStops.lightBlueViolet[0].color,
                },
                data: chartData?.map((item) => item[1]),
              },
            ],
    };
    setOption(newOption);
  }, [chartData, xlabel, data, linchart1, linchart2, linchart3]);

  const onEvents = (chart) => {
    chart.on("click", (event) => commonContext.handleChartClick(event.name, data));
  };

  return (
    <div className="chart-container mt-2">
      <ReactECharts option={option} onChartReady={onEvents} style={style} />
    </div>
  );
}

export default LineChart;
