/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useContext } from "react";
import { GoogleMap } from "@react-google-maps/api";
import { HttpServiceContext } from "../../../config/httpContext";
import { useLocation } from "react-router-dom";
import Sop from "../../common/sop";
import ViolationTabs from "../../common/dashboardtabs";
import { useJsApiLoader } from "@react-google-maps/api";
import { CommonServiceContext } from "../../../config/commonContext";
import config from "../../../config.json";

const mapContainerStyle = {
  width: "100%",
  border: "transparent",
  borderRadius: "25px",
};

const center = {
  lat: 37.783413877011085,
  lng: -122.42432091193467,
};

const Violation = () => {
  const mapRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState();
  const markerRef = useRef([]);
  const [mapKey, setMapKey] = useState(0);
  const [workOrder, setWorkOrder] = useState(false);
  const [voilation, setVoilation] = useState("-----");
  const [imageUrl, setImageUrl] = useState();
  const [sopSuccessful, setSopSuccessFul] = useState(false);
  const [activeTab, setActiveTab] = useState("Incidents");
  const [showWindow, setShowWindow] = useState(false);
  const [mapHeight, setMapHeight] = useState("95vh");
  const [routeName, setRouteName] = useState("");

  const httpContext = useContext(HttpServiceContext);
  const commonContext = useContext(CommonServiceContext);

  const location = useLocation();

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDMlVAALBCx-2tliIzQKST8qFwIyCvIRc4",
  });

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    commonContext.setSelectedType([]);
  };

  const [accidentDetails, setAccidentDetails] = useState({
    latitude: 37.783413877011085,
    longitude: -122.42432091193467,
    place: "",
  });

  const setPlacesName = async () => {
    try {
      let plc = await httpContext.getLocation(center.lat, center.lng);
      if (plc) {
        setAccidentDetails({
          latitude: center.lat,
          longitude: center.lng,
          place: plc.display_name,
        });
      }
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  const onMapLoad = (map) => {
    mapRef.current = map;

    // Clear existing markers
    markerRef.current.forEach((marker) => marker.setMap(null));
    markerRef.current = [];

    // Add new markers
    const markers = [
      new window.google.maps.Marker({
        position: { lat: 37.78332, lng: -122.4244 },
        map: map,
        title: "Incident Location",
        icon: {
          url: "./assets/speedLimit.png",
          scaledSize: new window.google.maps.Size(70, 70),
        },
      }),
      new window.google.maps.Marker({
        position: { lat: 37.78342, lng: -122.424288 },
        map: map,
        title: "Incident Location",
        icon: {
          url: "./assets/alert.png",
          scaledSize: new window.google.maps.Size(40, 40),
        },
      }),
    ];

    markerRef.current = markers;
  };

  useEffect(() => {
    setAccidentDetails({
      latitude: 37.783413877011085,
      longitude: -122.42432091193467,
      place: "",
    });

    setPlacesName();
    setRouteName(location.pathname);

    switch (location.pathname.toLocaleLowerCase()) {
      case "/overspeed":
        setImageUrl("./assets/overspeed.png");
        setVoilation("Over speed");
        break;
      case "/wronglane":
        setImageUrl("./assets/wronglane.jpg");
        setVoilation("Wrong lane");
        break;
      case "/signaljump":
        setImageUrl("./assets/signaljump.jpg");
        setVoilation("Signal jump");
        break;
      default:
        setImageUrl("");
        setVoilation("-----");
        break;
    }
  }, [location.pathname]);

  const handlesop = async () => {
    setSopSuccessFul(true);
    setWorkOrder(true);
    setMapHeight("60vh");

    let summary;
    let sopId;
    let sopDetails;
    var response;
    switch (location.pathname.toLocaleLowerCase()) {
      case "/overspeed":
        sopDetails = config.sop.sopOverspeed;
        response = await httpContext.createSop(
          sopDetails.summary,
          sopDetails.details,
          sopDetails.masterCriticalityId,
          sopDetails.masterCategoryTypeId
        );
        summary = sopDetails.summary;
        if (response) sopId = response.id;
        break;
      case "/wronglane":
        sopDetails = config.sop.sopWronglane;
        response = await httpContext.createSop(
          sopDetails.summary,
          sopDetails.details,
          sopDetails.masterCriticalityId,
          sopDetails.masterCategoryTypeId
        );
        summary = sopDetails.summary;
        if (response) sopId = response.id;
        break;
      case "/signaljump":
        sopDetails = config.sop.sopSignaljump;
        response = await httpContext.createSop(
          sopDetails.summary,
          sopDetails.details,
          sopDetails.masterCriticalityId,
          sopDetails.masterCategoryTypeId
        );
        if (response) sopId = response.id;
        summary = sopDetails.summary;
        break;
      default:
        break;
    }
    if (sopId) {
      const intervalId = setInterval(async () => {
        try {
          const status = await httpContext.verifyIncident(sopId);
          if (status && status.status === "Closed") {
            const deviceData = await httpContext.getDeviceState();
            if (deviceData) {
              switch (location.pathname.toLocaleLowerCase()) {
                case "/overspeed":
                  deviceData["SIM-005"].state = false;
                  break;
                case "/wronglane":
                  deviceData["SIM-007"].state = false;
                  break;
                case "/signaljump":
                  deviceData["SIM-006"].state = false;
                  break;
                default:
                  break;
              }
              httpContext.setDeviceState(deviceData);
            }
            clearInterval(intervalId);
          }
        } catch (error) {
          console.error("Error verifying incident:", error);
        }
      }, 5000);
    }
  };

  const closeSOP = () => {
    setSopSuccessFul(false);
  };

  const openWindow = () => {
    setShowWindow(true);
  };

  const closeWindow = () => {
    setShowWindow(false);

    setMapHeight("60vh");
  };

  if (loadError) {
    return <div>Error Loading map</div>;
  }
  return isLoaded ? (
    <div className="sop-panel p-2">
      {!commonContext.showAnalytics && !showWindow && (
        <div className="row">
          <div className="col-7">
            <GoogleMap
              key={mapKey} // Ensure map reinitializes on key change
              mapContainerStyle={{ ...mapContainerStyle, height: mapHeight }}
              center={center}
              zoom={19}
              onLoad={onMapLoad}
            ></GoogleMap>
            {workOrder && (
              <div className="mytabs m-2">
                <div className="tabOptions">
                  <div>
                    <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                      Incidents
                    </button>
                  </div>
                  <div>
                    <th className="mini col-6">
                      <img src="/maximize.png" alt="" className="maximize" onClick={openWindow} />
                    </th>
                  </div>
                </div>
                <div className="tabContent p-0">
                  {activeTab === "Incidents" && <iframe className="sop-iframe" src={httpContext.sop} title="Sop" />}
                </div>
              </div>
            )}
          </div>
          <div className="col-5">
            <div className="m-1 side-panel">
              <div className="m-0 p-2 details">
                <div className="fs-5 fw-bold text-center">Incident Details</div>
                <div className="m-2">
                  Location: &nbsp;
                  <span className="fs-7 fw-lighter">
                    <a href={`https://www.google.com/maps/place/${accidentDetails.latitude},${accidentDetails.longitude}`}>{accidentDetails.place}</a>
                  </span>
                </div>
                <div className="m-2">
                  Coordinates: &nbsp;
                  <span className="fs-7 fw-lighter">
                    {accidentDetails.latitude}, {accidentDetails.longitude}
                  </span>
                </div>
                <div className="m-2">
                  Violation-Type:&nbsp;
                  <span className="fw-lighter">{voilation}</span>
                </div>
              </div>
            </div>
            <div className="m-1 side-panel side-panel-h p-3">
              <div>
                <video autoPlay muted loop width="100%" height="100%">
                  {routeName === "/overspeed" && <source src="./assets/overspeed.mp4" type="video/mp4" />}
                  {routeName === "/wronglane" && <source src="./assets/wronglane.mp4" type="video/mp4" />}
                  {routeName === "/signaljump" && <source src="./assets/signaljump.mp4" type="video/mp4" />}
                </video>
                <img className="m-1 d-block mx-auto image" width="40%" height="80vh" src={imageUrl} alt="Details" />
              </div>
            </div>
            <div className="p-2 side-panel side-panel-down">
              <div className="fw-bold fs-5 p-2">Select Option for SOP</div>
              <div className="main-block-report">
                <label>
                  <input type="radio" name="reportOptions" />
                  Ignore
                </label>
                <label>
                  <input type="radio" name="reportOptions" onChange={() => handleOptionChange("File Report")} />
                  File Report
                </label>
              </div>
              {selectedOption && (
                <div className="m-2 action p-2">
                  <div className="fw-bold fs-6">Action</div>
                  <div className="d-flex fs-7 align-items-center justify-content-around">
                    <div>Create a new work order request (File Report)</div>
                    <div className="">
                      <button className="bttns btn fs-7" onClick={handlesop}>
                        Work Order
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {showWindow && <ViolationTabs closeWindow={closeWindow} active={activeTab} label={"ViolationTabs"} />}
      {sopSuccessful && <Sop closeSOP={closeSOP} settab={() => setSopSuccessFul(false)} />}
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default Violation;
