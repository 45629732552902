import React from "react";
import ReactECharts from "echarts-for-react";

function Pyramid(props) {
  const { data, style } = props;

  const generateSpiralData = () => {
    const data = [];
    const numPoints = 100;
    for (let i = 0; i < numPoints; i++) {
      const angle = (i / numPoints) * Math.PI * 4; // Create a spiral effect
      const radius = angle; // Use angle as radius
      const x = radius * Math.cos(angle);
      const y = radius * Math.sin(angle);
      data.push([x, y]);
    }
    return data;
  };

  const option = {
    title: {
      text: "Top 5 Performers",

      textStyle: {
        color: "#ffffff",
        fontSize: 12,
      },
      textAlign: "left",
      left: "3%",
      top: "1%",
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c}%",
    },
    toolbox: {},
    series: [
      {
        name: "",
        type: "funnel",
        left: "10%",
        top: 60,
        bottom: 10,
        width: "80%",
        min: 0,
        max: 100,
        minSize: "0%",
        maxSize: "100%",
        sort: "ascending",
        gap: 2,
        label: {
          show: true,
          position: "inside",
        },
        labelLine: {
          length: 10,
          lineStyle: {
            width: 1,
            type: "solid",
          },
        },
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 1,
        },
        emphasis: {
          label: {
            fontSize: 20,
          },
        },
        data: data?.values,
      },
    ],
  };

  return (
    <div className="chart-container mt-2">
      <ReactECharts option={option} style={style} />
    </div>
  );
}

export default Pyramid;
